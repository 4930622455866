import { createContext, useEffect, useState } from "react";
import { Loader } from "pixel";
import { BASE_URL } from "../../utils/constants";
import FullPageError from "../../components/display/FullPageError";
import { reportErrorToSentry, setSentryTag } from "../../global/sentry/sentry";

interface ConfigurationProviderProps {
  children: React.ReactNode;
}

export interface TenantConfig {
  tenant: string;
  realmUrl: string;
  client_id: string;
  client_secret: string;
  fp: {
    fpapi_endpoint: string;
    fpdashboard_api_endpoint: string;
    tenant: string;
  };
}

export interface ConfigurationContextProps {
  tenantConfig: TenantConfig | null;
  tenantLogos: Record<string, string> | null;
}

export const ConfigurationContext =
  createContext<ConfigurationContextProps | null>(null);

const ConfigurationProvider = ({ children }: ConfigurationProviderProps) => {
  const [tenantConfig, setTenantConfig] = useState<TenantConfig | null>(null);
  const [tenantLogos, setTenantLogos] = useState<Record<string, string> | null>(
    null
  );
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const configRes = await fetch("/dashboard_config", {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        const config = await configRes.json();

        // const tenantLogosRes = await fetch(BASE_URL + "/tenant_logos_config", {
        //   headers: {
        //     Accept: "application/json",
        //     "Content-Type": "application/json",
        //   },
        // });
        // const tenantLogosConfig = await tenantLogosRes.json();

        setTenantConfig(config);
        //setTenantLogos(tenantLogosConfig);
        // setSentryTag({ tagName: "tenant", tagValue: config?.fp?.tenant });
        setSentryTag({ tagName: "app", tagValue: process.env.REACT_APP_TYPE });
      } catch (e) {
        reportErrorToSentry(e);
        setError("Error fetching config");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (error) {
    return (
      <FullPageError
        errorTitle={error}
        errorDescription="Retry or check back later"
        actionText="Retry"
        action={() => window.location.reload()}
      />
    );
  }

  return loading ? (
    <Loader variant="fullpage" />
  ) : (
    <ConfigurationContext.Provider value={{ tenantConfig, tenantLogos }}>
      {children}
    </ConfigurationContext.Provider>
  );
};

export default ConfigurationProvider;
