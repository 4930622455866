import { tenantEnv } from "./utils";

/**
 * @constant /fp-dashboard
 */
export const BASE_URL: string = process.env?.PUBLIC_URL as string;

export const FALLBACK_LOGO_URL = `${BASE_URL}/fallback-logo.png`;

export const DASHBOARD_LOGO_URL = `${BASE_URL}/dashboard-logo.ico`;

export const FINTECH_PRIMITIVES_LOGO_URL = `${BASE_URL}/fp-badge.svg`;

export const NOT_FOUND_IMAGE_URL = `${BASE_URL}/not-found.svg`;

export const AUTH_REDIRECT_URI = window.location.origin + BASE_URL;

export const OLD_DASHBOARD_LINK = tenantEnv.isProduction
  ? "https://dashboard.fintechprimitives.com/"
  : "https://sandbox.dashboard.fintechprimitives.com/";

export const OLD_DASHBOARD_PROD_DISASTER_RECOVERY_LINK =
  "https://dashboard-dr.fintechprimitives.com/";

export const OLD_DASHBOARD_URL = tenantEnv.isProduction
  ? "https://dashboard.fintechprimitives.com/live/"
  : "https://sandbox.dashboard.fintechprimitives.com/sandbox/";

export const CAMS_FUNDSNET_LOGIN_URL = "https://fundsnet.camsonline.com/ecrms/index.aspx";
export const KFin_FUNDSNET_CHANNEL_PARTNERS_LOGIN_URL =
  "https://cpupload.kfintech.com/ChannelServices/CP_Login.aspx";
export const KFin_FUNDSNET_RIA_LOGIN_URL =
  "https://cpupload.kfintech.com/ChannelServices/RIA_Login.aspx";
export const INTERNAL_USER_EMAIL_DOMAIN = "cybrilla";

export const ORDER_SUBMISSION_AVO_URL = tenantEnv.isProduction
  ? "https://dashboard.fintechprimitives.com/live/resources/order_submissions"
  : "https://sandbox.dashboard.fintechprimitives.com/sandbox/resources/order_submissions";

export const THIRDEYE_URL_WEB_ORIGIN = tenantEnv.isProduction
  ? "http://{{realm}}.thirdeye.fpapps.io"
  : "http://{{realm}}.thirdeye.s.finprim.com";
export const THIRDEYE_POST_LOGOUT_REDIRECT_URL = tenantEnv.isProduction
  ? "http://{{realm}}.thirdeye.fpapps.io/login"
  : "http://{{realm}}.thirdeye.s.finprim.com/login";
export const THIRDEYE_REDIRECT_URL = tenantEnv.isProduction
  ? "http://{{realm}}.thirdeye.fpapps.io/oauth-authorized/{{realm}}"
  : "http://{{realm}}.thirdeye.s.finprim.com/oauth-authorized/{{realm}}";
export const INTERNAL_THIRDEYE_SETUP_REDIRECT_URL = tenantEnv.isProduction
  ? ["http://{{realm}}.thirdeye.fpapps.io/oauth-authorized/fp-apps"]
  : ["http://{{realm}}.thirdeye.s.finprim.com/oauth-authorized/fp-apps"];
export const INTERNAL_THIRDEYE_SETUP_POST_LOGOUT_REDIRECT_URL = tenantEnv.isProduction
  ? "http://{{realm}}.thirdeye.fpapps.io/oauth-authorized/*"
  : "http://{{realm}}.thirdeye.s.finprim.com/oauth-authorized/*";

export const DASHBOARD_WIAM_REDIRECT_URL = window.location.origin + BASE_URL;
export const DASHBOARD_WIAM_CLIENT_ID = tenantEnv.isProduction ? "fp-apps-live" : "fp-apps-test";
export const DASHBOARD_WIAM_CLIENT_NAME = "FP Dashboard";
export const FP_OPS_PORTAL_WIAM_CLIENT_ID = tenantEnv.isProduction
  ? "fp-ops-portal-live"
  : "fp-ops-portal-test";
export const FP_OPS_PORTAL_WIAM_CLIENT_NAME = "FP OPS Portal";
export const THIRDEYE_WIAM_CLIENT_ID = "thirdeye";
export const THIRDEYE_WIAM_CLIENT_NAME = "Thirdeye";
export const DASHBOARD_BACKEND_CLIENT_ID = tenantEnv.isProduction
  ? "fp-dashboard-live"
  : "fp-dashboard-test";
export const DASHBOARD_BACKEND_CLIENT_NAME = "Fp Dashboard Backend Client";
export const THIRDEYE_NAVIGATION_URL = tenantEnv.isProduction
  ? "https://{{realm}}.thirdeye.fpapps.io/login/"
  : "https://{{realm}}.thirdeye.s.finprim.com/login/";
export const VALID_THIRDEYE_ROLES = ["manager", "analyst", "operations", "sqllab"];

export const PARTNER_FLOW_ENABLED_TENANTS = ["prueba", "nipponpoc"];

export const LIMITED_DATA_TABLE_INFO_MESSAGE =
  "Displaying up to 100 records based on your selection.";

export const PRODUCT_DOCS_URL = "https://docs.fintechprimitives.com/";
