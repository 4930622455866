import React, { useState, useEffect } from "react";

interface DateTimeDisplayProps {}

const DateTime: React.FC<DateTimeDisplayProps> = () => {
  const [dateTime, setDateTime] = useState(new Date());

  const formatTime = (): string => {
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    };
    return new Date(dateTime).toLocaleTimeString("en-US", options);
  };

  const formatDate = (): string => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "short",
      // year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(dateTime).toLocaleDateString("en-US", options);
  };

  useEffect(() => {
    let timeOutId: any = null;
    function getTime() {
      let interval = (60 - new Date().getSeconds()) * 1000 + 5;
      setDateTime(new Date());
      timeOutId = setTimeout(getTime, interval);
    }
    getTime();

    return () => {
      timeOutId && clearTimeout(timeOutId);
    };
  }, []);

  return (
    <div className="date-time font-semi-bold font-opensans text-size-14 md:text-size-base text-neutral-dark-0 dark:text-neutral-80  bg-error-5 dark:bg-error-20 mt-2 flex flex rounded-md px-2 py-2 font-semibold">
      <span className="mr-1">{formatTime()}</span>
      <span className="mr-1"> • </span>
      <span>{formatDate()}</span>{" "}
    </div>
  );
};

export default DateTime;
