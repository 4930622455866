import { EventObject } from "@fintechprimitives/fpapi";
import type { ColDef, ICellRendererParams } from "ag-grid-community";
import { Button, Icon, Loader, Message, Modal, TextInput } from "pixel";
import { useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useFpapi } from "../../../context/fpapi/useFpapi";
import { reportErrorToSentry } from "../../../global/sentry/sentry";
import { useDebounce } from "../../../hooks/useDebounce";
import {
  filterSearchParamsByKey,
  filterTruthyValues,
  formatDateForTable,
  serialiseFilters,
  setSearchParamsByKey,
} from "../../../utils/utils";
import FullPageError, { FullPageErrorProps } from "../../display/FullPageError";
import FilterButton from "../../display/ListingPage/FilterButton";
import FilterContainer from "../../display/ListingPage/FilterContainer";
import FilterDropdownContainer from "../../display/ListingPage/FilterDropdownContainer";
import HeaderContainer from "../../display/ListingPage/HeaderContainer";
import PageHeading from "../../display/ListingPage/PageHeading";
import SearchContainer from "../../display/ListingPage/SearchContainer";
import SearchFilterContainer from "../../display/ListingPage/SearchFilterContainer";
import ThemedDataTable from "../../display/ThemedDataTable";
import EventFilterPills from "./EventFilterPills";
import EventFilters from "./EventFilters";
import { EventTypeOptions, Filters } from "./types";
import FpApi from "../../../context/fpapi/fpapi";
import { ConditionalRenderer } from "../../../utils/helpers";
import { LIMITED_DATA_TABLE_INFO_MESSAGE } from "../../../utils/constants";

const renderIdColumn = (params: ICellRendererParams, openModal: (data: EventObject) => void) => {
  return (
    <Button
      variant="text"
      onClick={() => {
        openModal(params.data);
      }}
      size="sm"
    >
      {params.data?.id}
    </Button>
  );
};

interface EventListingProps {
  defaultParams?: Filters;
  paramIdentifier?: string;
  showAllFilters?: boolean;
}

const fetchData = async (batchFilters: Filters, fpapi: FpApi | undefined) => {
  const searchKey = batchFilters.search ? decodeURIComponent(batchFilters.search) : "";

  if (searchKey) {
    const eventObject = await fpapi?.fpDashboardClient?.events().fetch(searchKey)!;
    return [eventObject];
  }
  const dateRange = batchFilters.date_range?.split("--");

  const params = {
    fp_object_id: batchFilters.fp_object_id,
    type: batchFilters.type,
    from_date: dateRange?.[0] ?? null,
    to_date: dateRange?.[1] ?? null,
  };

  const eventsResponse = await fpapi?.fpDashboardClient
    ?.events()
    .fetchAll(filterTruthyValues(params));

  return eventsResponse?.data;
};

function EventsListing({
  defaultParams,
  paramIdentifier = "",
  showAllFilters = true,
}: EventListingProps) {
  const { fpapi } = useFpapi();

  const [searchParams, setSearchparams] = useSearchParams({});
  const [rowData, setRowData] = useState<EventObject[]>([]);
  const [batchFilters, setBatchFilters] = useState<Filters>(
    defaultParams || filterSearchParamsByKey(searchParams, paramIdentifier, true)
  );
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);
  const [showFilter, setShowFilter] = useState(false);
  const [modalDetails, setModalDetails] = useState<EventObject | null>(null);
  const [eventTypeOptions, setEventTypeOptions] = useState<EventTypeOptions>([]);
  const [error, setError] = useState<FullPageErrorProps | null>(null);
  const [loading, setLoading] = useState(false);

  const pageSizeLimit = 20;

  const debouncedInterval = 3 * 1000;
  const debouncedSearchQuery = useDebounce(searchQuery, debouncedInterval);

  const openModal = (data: EventObject) => {
    setModalDetails(data);
  };

  const colDefs = useMemo(() => {
    return [
      {
        headerName: "EVENT ID",
        field: "id",
        cellRenderer: function (params: ICellRendererParams) {
          if (params?.value !== undefined) {
            return renderIdColumn(params, openModal);
          } else if (params?.node?.rowIndex && params?.node?.rowIndex >= 0) {
            return <p>Loading...</p>;
          }
        },
        headerClass: "ag-center-aligned-header",
      },
      {
        headerName: "EVENT TYPE",
        field: "type",
        headerClass: "ag-center-aligned-header",
      },
      {
        headerName: "OBJECT ID",
        field: "data.object.id",
        headerClass: "ag-center-aligned-header",
        valueFormatter: (params: ICellRendererParams) => {
          return params?.value?.toString();
        },
      },
      {
        headerName: "TIME",
        field: "time",
        cellRenderer: (params: ICellRendererParams) => {
          return <span>{formatDateForTable(params?.value, "dd MMM yyyy HH:mm:ss:ms")}</span>;
        },
        getQuickFilterText: function (params) {
          return formatDateForTable(params?.value, "dd MMM yyyy HH:mm:ss:ms");
        },
        headerClass: "ag-center-aligned-header",
      },
    ] as ColDef[];
  }, []);

  const fetchEventTypeOptions = async () => {
    try {
      setLoading(true);
      setError(null);

      const eventTypesResponse = await fpapi?.fpDashboardClient?.events().fetchAllEventTypes()!;

      const options: EventTypeOptions = eventTypesResponse?.data?.map((eventType) => {
        return { label: eventType, value: eventType, id: eventType };
      });

      setEventTypeOptions(options);
    } catch (e) {
      window.debug.error(e);
      setShowFilter(false);
      setError({
        errorTitle: "Error fetching event type options",
        errorDescription: "Check back later",
      });
      reportErrorToSentry(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setError(null);

        const data = await fetchData(batchFilters, fpapi);
        data && setRowData(data);
      } catch (e) {
        window.debug.error(e);
        setError({
          errorTitle: "Error fetching data",
          errorDescription: "Check back later or adjust your filters",
        });
        reportErrorToSentry(e);
      } finally {
        setLoading(false);
      }
    })();
  }, [batchFilters, fpapi]);

  useEffect(() => {
    if (debouncedSearchQuery !== undefined) {
      setBatchFilters({
        search: encodeURIComponent(debouncedSearchQuery.trim()),
      });
    }
  }, [debouncedSearchQuery]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSearchQuery(value);
  };

  const handleToggleFilter = () => {
    setShowFilter(!showFilter);
  };

  const handleUpdateBatchFilters = (updatedFilters: Filters) => {
    setSearchParamsByKey(
      serialiseFilters(filterTruthyValues(updatedFilters)),
      paramIdentifier,
      setSearchparams
    );
    setBatchFilters(filterTruthyValues(updatedFilters));
    setSearchQuery(undefined);
  };

  const renderTable = () => {
    if (error) {
      return (
        <FullPageError
          errorTitle={error?.errorTitle}
          errorDescription={error?.errorDescription}
          actionText={error?.actionText}
          action={error?.action}
        />
      );
    }

    return (
      <div onClick={() => setShowFilter(false)}>
        <ThemedDataTable
          columnDefs={colDefs}
          rowData={rowData}
          height={"70vh"}
          cacheBlockSize={pageSizeLimit}
          rowModelType="clientSide"
        />
      </div>
    );
  };

  const renderPage = () => {
    return (
      <>
        <HeaderContainer>
          <PageHeading title="Events" />
        </HeaderContainer>

        <div className="relative">
          <ConditionalRenderer check={showAllFilters}>
            <SearchFilterContainer>
              <SearchContainer onClick={() => setShowFilter(false)}>
                <TextInput
                  type="text"
                  placeholder="Search by event id"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  suffix={<Icon name="Search" />}
                  maxLength={50}
                  theme={{
                    bgColorClass: "bg-neutral-0",
                  }}
                />
              </SearchContainer>

              <FilterContainer>
                <FilterButton onClick={handleToggleFilter} showFilter={showFilter} />

                <FilterDropdownContainer showFilter={showFilter}>
                  <EventFilters
                    selectedFilters={batchFilters}
                    handleSubmission={(formData) => {
                      handleUpdateBatchFilters(formData);
                      setShowFilter(false);
                    }}
                    eventTypeOptions={eventTypeOptions}
                    fetchEventTypeOptions={fetchEventTypeOptions}
                  />
                </FilterDropdownContainer>
              </FilterContainer>
            </SearchFilterContainer>

            <EventFilterPills
              selectedFilters={batchFilters}
              handleUpdateBatchFilters={handleUpdateBatchFilters}
            />
          </ConditionalRenderer>

          <Message
            type="alert"
            variant="field"
            title={`${LIMITED_DATA_TABLE_INFO_MESSAGE}`}
            iconVisibility={false}
            customClass="mb-5"
          />

          {renderTable()}
        </div>

        <Modal
          title="Event data"
          isOpen={Boolean(modalDetails)}
          toggleModal={() => {
            setModalDetails(null);
          }}
          fullWidth
          contentHeightMax={true}
        >
          <pre>
            <code>{JSON.stringify(modalDetails, null, 4)}</code>
          </pre>
        </Modal>
      </>
    );
  };

  return loading ? <Loader variant="fullpage" /> : renderPage();
}
export default EventsListing;
