import { ReactNode, createContext, useCallback, useEffect, useMemo, useState } from "react";

import FpApi, { Config, PartialConfig } from "./fpapi";

import { Loader } from "pixel";

import FullPageError from "../../components/display/FullPageError";
import { reportErrorToSentry } from "../../global/sentry/sentry";
import { useAuth } from "../auth/useAuth";
import { useUserInfo } from "../userinfo/useUserInfo";
import { UserType } from "./types";

export interface IFpApiContext {
  fpapi: FpApi | undefined;
  rebuildFpApi: () => Promise<FpApi | undefined>;
}

type FpApiProviderProps = {
  children: ReactNode;
  userType: UserType;
  customFPConfig: {
    fpApiUrl: string | undefined;
    fpDashboardApiUrl: string | undefined;
  };
};

export const FpApiContext = createContext<IFpApiContext>({} as IFpApiContext);

export const FpApiProvider = ({ children, userType, customFPConfig }: FpApiProviderProps) => {
  const auth = useAuth();
  const userInfo = useUserInfo();

  const [fpapi, setFpapi] = useState<FpApi | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const config: PartialConfig = useMemo(() => {
    return {
      fpApiBaseURL: customFPConfig.fpApiUrl,
      fpDashboardApiBaseURL: customFPConfig.fpDashboardApiUrl,
      activeTenant: userInfo?.data.activeTenant,
      userType,

      // ****** Admin *******
      admin: {
        accessToken: auth?.accessToken,
      },
    };
  }, [userType, customFPConfig, auth?.accessToken, userInfo?.data.activeTenant]);

  const initiateBuild = useCallback(async (config: PartialConfig) => {
    try {
      setLoading(true);

      if (![config.userType, config.fpApiBaseURL, config.fpDashboardApiBaseURL].every(Boolean)) {
        return;
      }

      switch (config.userType) {
        case "ADMIN": {
          if (!config?.admin?.accessToken) return;

          const _fpapi = await FpApi.buildWithAdminToken(config as Config);
          setFpapi(_fpapi);
          return _fpapi;
        }
        default: {
          return;
        }
      }
    } catch (e) {
      window.debug.error(e);
      setError("Error initialising fpapi");
      reportErrorToSentry(e);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    initiateBuild(config);
  }, [initiateBuild, config]);

  const rebuildFpApi = async () => {
    const fpapi = await initiateBuild(config);
    return fpapi;
  };

  if (error) {
    return (
      <FullPageError
        errorTitle={error}
        errorDescription="Retry or check back later"
        actionText="Retry"
        action={() => window.location.reload()}
      />
    );
  }
  return (
    <>
      {loading && <Loader variant="fullpage" />}

      <FpApiContext.Provider value={{ fpapi, rebuildFpApi }}>{children}</FpApiContext.Provider>
    </>
  );
};
