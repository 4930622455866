import { useNavigate } from "react-router-dom";
import { useUserInfo } from "./packages/context/userinfo/useUserInfo";
import { isAuthorisedRole } from "./packages/utils/utils";
import UnauthorisedPage from "./packages/components/display/UnauthorisedPage";
import { RouteAccessMap } from "./packages/utils/routeAccess";

type ProtectedRoutesProps = {
  path: string;
  component: React.ReactNode;
};

const ProtectedRoutes = ({ path, component }: ProtectedRoutesProps) => {
  const navigate = useNavigate();
  const userInfo = useUserInfo();
  const currentRoles = userInfo?.data.userInfo?.roles;

  return isAuthorisedRole(RouteAccessMap[path] ?? [], currentRoles) ? (
    component
  ) : (
    <UnauthorisedPage
      errorTitle="Access denied"
      actionText="Go to homepage"
      action={() => navigate("/")}
    />
  );
};
export default ProtectedRoutes;
