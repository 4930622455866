import { Button, ErrorSvg, Panel } from "pixel";
import { MouseEvent } from "react";

export type FullPageErrorProps = {
  errorTitle: string;
  errorDescription?: string;
  action?: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  actionText?: string;
};

const FullPageError = ({
  errorTitle,
  errorDescription,
  action,
  actionText,
}: FullPageErrorProps) => {
  const titleClasses = `text-primary-DN0 text-center font-semibold text-size-20 pb-2 font-opensans dark:text-primary-DN110`;
  const descriptionClasses = `mb-6 text-primary-N60 text-center text-size-14 mt-0`;
  return (
    <Panel>
      <div
        className="flex flex-col items-center justify-center"
        style={{ minHeight: "80vh" }}
      >
        <div className="mb-4">
          <ErrorSvg />
        </div>

        <h3 className={titleClasses}>{errorTitle}</h3>

        {errorDescription ? (
          <p className={descriptionClasses}>{errorDescription}</p>
        ) : null}

        {action ? (
          <Button variant="text" onClick={action}>
            {actionText}
          </Button>
        ) : null}
      </div>
    </Panel>
  );
};
export default FullPageError;
