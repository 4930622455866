import { PageTitle, PageTitleProps } from "pixel";

function PageHeading(props: Readonly<PageTitleProps>) {
  return (
    <div className="flex-1">
      <PageTitle {...props} />
    </div>
  );
}
export default PageHeading;
