import * as Sentry from "@sentry/react";
import {
  TENANT_ENV,
  activeTenantLocalStorage,
  isUpstreamError,
  tenantEnv,
} from "../../utils/utils";

const disableSentry = tenantEnv.isQa;

export const initializeErrorMonitoring = () => {
  if (disableSentry) {
    return;
  }

  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: TENANT_ENV,
    attachStacktrace: true, // https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/#attach-stacktrace
    integrations: function (integrations) {
      // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/
      return integrations.filter(function (integration) {
        return integration.name !== "Breadcrumbs";
      });
    },
    sampleRate: 1.0, // https://docs.sentry.io/platforms/javascript/guides/react/configuration/options/#sample-rate
    tracesSampler: (samplingContext) => {
      // https://develop.sentry.dev/sdk/telemetry/traces/#tracessampler
      // https://forum.sentry.io/t/disable-transactions/11260
      if (tenantEnv.isProduction) {
        return 0.75;
      }

      return 0;
    },
    normalizeDepth: 10, //https://github.com/getsentry/sentry-javascript/issues/1964#issuecomment-688482615
    release: process.env.REACT_APP_RELEASE_VERSION,
  });
};

export const setSentryTag = ({ tagName, tagValue }: any) => {
  if (disableSentry) {
    return;
  }

  Sentry.setTag(tagName, tagValue);
};

export const setSentryUser = ({ id = null, username = null, email = null }) => {
  if (disableSentry) {
    return;
  }

  const userData: any = {
    id,
    username,
    email,
  };

  Sentry.setUser(userData);
};

export const reportException = (error: any, data?: any) => {
  if (disableSentry) {
    return;
  }

  Sentry.captureException(error, {
    contexts: { support_data: data },
  });
};

export const reportErrorToSentry = (e: any) => {
  if (disableSentry) {
    return;
  }

  const activeTenant = activeTenantLocalStorage.getActiveTenant();

  let additionalData;
  if (activeTenant) {
    additionalData = { activeTenant };
  } else {
    additionalData = null;
  }

  if (isUpstreamError(e)) {
    reportException(e?.data, additionalData);
  } else {
    reportException(e, additionalData);
  }
};
