import { useContext } from "react";
import { ConfigurationContext } from "./ConfigurationContext";

export const useConfiguration = () => {
  const config = useContext(ConfigurationContext);

  if (!config) {
    throw new Error("useConfiguration must be used within a ConfigurationProvider");
  }

  return config;
};
