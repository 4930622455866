import { Loader } from "pixel";
import { useMemo } from "react";
import { useConfiguration } from "../configuration/useConfiguration";
import { FpApiProvider } from "./FpApiProvider";

const FpApiProviderWrapper = ({ children }: { children: React.ReactNode }) => {
  const config = useConfiguration();

  const adminConfig = useMemo(() => {
    return {
      fpApiUrl: config?.tenantConfig?.fp?.fpapi_endpoint,
      fpDashboardApiUrl: config?.tenantConfig?.fp?.fpdashboard_api_endpoint,
    };
  }, [config]);

  return config ? (
    <FpApiProvider userType={"ADMIN"} customFPConfig={adminConfig}>
      {children}
    </FpApiProvider>
  ) : (
    <Loader variant="fullpage" />
  );
};
export default FpApiProviderWrapper;
