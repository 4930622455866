import { ReactNode } from "react";

type FilterContainerProps = {
  children: ReactNode;
};
function FilterContainer({ children }: Readonly<FilterContainerProps>) {
  return (
    <div className="ml-4 flex items-center justify-end" id="filter-container">
      {children}
    </div>
  );
}
export default FilterContainer;
