import { Avatar, Button, Dropdown, Radio } from "pixel";
import { Link } from "react-router-dom";
import { useAuth } from "../packages/context/auth/useAuth";
import { useUserInfo } from "../packages/context/userinfo/useUserInfo";
import { useTheme } from "../packages/hooks/useTheme";
import useUIState from "../packages/hooks/useUIState";
import { ProtectedMenu } from "../packages/utils/routeHelpers";
import { hasVisitedConsoleSessionStorage } from "../packages/utils/utils";
import Username from "./Username";
import { PRODUCT_DOCS_URL } from "../packages/utils/constants";

const getsidebarProfileClass = (isSideBarCollapsed: boolean) =>
  isSideBarCollapsed
    ? `md:fixed bottom-0 left-0 md:w-20 md:bg-neutral-5 md:dark:bg-neutral-dark-5 pt-2 md:pt-0 sidebar-profile-nav border-t dark:border-neutral-dark-20`
    : `md:bg-neutral-5 md: dark:bg-neutral-dark-5 md:fixed bottom-0 left-0 md:w-64 pt-2 md:pt-0 sidebar-profile-nav border-t dark:border-neutral-dark-20`;

const getprofileDropdownClass = (isSideBarCollapsed: boolean) =>
  isSideBarCollapsed ? `md:w-56 block mb-2` : `block mb-2`;

const MenuFooter = () => {
  const auth = useAuth();
  const userInfo = useUserInfo();
  const { theme, setTheme } = useTheme();
  const { isSideBarCollapsed } = useUIState();

  const currentUserName = userInfo?.data.userInfo?.given_name ?? "";

  return (
    <div className={getsidebarProfileClass(isSideBarCollapsed)}>
      <Dropdown
        variant="stickyBottom"
        customClass={getprofileDropdownClass(isSideBarCollapsed)}
        triggerContent={
          <div>
            <button className="flex w-full items-center justify-between">
              <span className="dark:text-neutral-40 flex items-center">
                <Avatar customClass="w-9 h-9 text-size-14 mr-4">
                  {currentUserName.charAt(0).toUpperCase()}
                </Avatar>

                <Username
                  isSideBarCollapsed={isSideBarCollapsed}
                  currentUserName={currentUserName}
                />
              </span>
            </button>
          </div>
        }
      >
        <ul className="px-4 py-4">
          <ProtectedMenu matchRoute="/fp-console">
            <li className="bg-neutral-150 dark:bg-neutral-dark-5 hover:bg-neutral-5 dark:hover:bg-neutral-dark-0 mb-2 rounded-md">
              <Link
                className="dark:text-neutral-40 text-neutral-110 block px-2 py-2"
                to="/fp-console"
                onClick={() => hasVisitedConsoleSessionStorage.set("true")}
              >
                FP Console
              </Link>
            </li>
          </ProtectedMenu>
          <li className="bg-neutral-150 dark:bg-neutral-dark-5 hover:bg-neutral-5 dark:hover:bg-neutral-dark-0 mb-2 rounded-md">
            <Link
              className="dark:text-neutral-40 text-neutral-110 block px-2 py-2"
              to={PRODUCT_DOCS_URL}
              target="_blank"
              rel="noreferrer"
            >
              Product guide
            </Link>
          </li>
          <li className="bg-neutral-150 dark:bg-neutral-dark-5 hover:bg-neutral-5 dark:hover:bg-neutral-dark-0 mb-2 rounded-md">
            <Link
              className="dark:text-neutral-40 text-neutral-110 block px-2 py-2"
              to="https://fintechprimitives.com/docs/api/"
              target="_blank"
              rel="noreferrer"
            >
              API reference
            </Link>
          </li>
          <li>
            <div className="px-2">
              <Radio
                variant="solidIcon"
                name="radioname mb-2"
                checked={theme === "dark" ? 0 : 1}
                onChange={(e) => {
                  const newTheme = e.target.value === "Dark" ? "dark" : "light";
                  setTheme(newTheme);
                }}
                group={["Dark", "Light"]}
                customClass="mb-4 md:mb-2"
              />
            </div>
          </li>
          <li>
            <Button
              variant="secondary"
              onClick={() => {
                localStorage.removeItem("isMobileMenuCollapsed");
                auth.logout();
              }}
              size="sm"
              fullwidth
            >
              Logout
            </Button>
          </li>
        </ul>
      </Dropdown>
    </div>
  );
};
export default MenuFooter;
