import { Loader } from "pixel";
import { createContext, useEffect, useMemo, useState } from "react";
import FullPageError from "../../components/display/FullPageError";
import { reportErrorToSentry } from "../../global/sentry/sentry";
import { activeTenantLocalStorage, parseJWT } from "../../utils/utils";
import { useAuth } from "../auth/useAuth";
import { useConfiguration } from "../configuration/useConfiguration";
import { UserInfoResponse } from "./types";

export type UserInfoContextType = {
  data: {
    userInfo: UserInfoResponse | null;
    activeTenant: string | null;
    tenantOptions?: { label: string; value: string; id: string }[];
  };
  isLoading: boolean;
  error: string | null;
  updateActiveTenant: (tenantName: string) => void;
  updateActiveTenantWithoutReload: (tenantName: string) => void;
};
export const UserInfoContext = createContext<UserInfoContextType | null>(null);

type UserInfoProviderProps = {
  children: React.ReactNode;
};
function UserInfoProvider({ children }: Readonly<UserInfoProviderProps>) {
  const auth = useAuth();
  const config = useConfiguration();

  const [userInfo, setUserInfo] = useState<UserInfoResponse | null>(null);
  const [activeTenant, setActiveTenant] = useState(() =>
    activeTenantLocalStorage.getActiveTenant()
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const tenantOptions = useMemo(() => {
    return userInfo?.tenants.map((tenant) => {
      return { label: tenant, value: tenant, id: tenant };
    });
  }, [userInfo?.tenants]);

  const fetchUserInfo = async (accessToken: string): Promise<UserInfoResponse> => {
    const baseUrl = config?.tenantConfig?.fp.fpapi_endpoint;
    const tokenIssuerSplit = parseJWT(auth.accessToken as string)?.iss.split("/");
    const authRealm = tokenIssuerSplit[tokenIssuerSplit.length - 1];
    const url = `${baseUrl}/auth/realms/${authRealm}/protocol/openid-connect/userinfo`;

    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });

    return res.json();
  };

  const updateActiveTenant = (tenantName: string) => {
    setActiveTenant(tenantName);
    activeTenantLocalStorage.setActiveTenant(tenantName);
    window.location.reload(); // reinitialise fpapi with new tenant and fetch tenant specific data
  };

  const updateActiveTenantWithoutReload = (tenantName: string) => {
    setActiveTenant(tenantName);
    activeTenantLocalStorage.setActiveTenant(tenantName);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        if (auth.accessToken) {
          const data = await fetchUserInfo(auth.accessToken);

          if (
            (!activeTenant && data.tenants.length > 0) ||
            (activeTenant && data.tenants.length > 0 && !data.tenants?.includes(activeTenant)) ||
            (activeTenant !== "undefined" && data.tenants.length === 0)
          ) {
            // activeTenant present in localStorage but not in user info. This means user has switched to a different tenant
            updateActiveTenant(data?.tenants?.[0]);
            return;
          }

          setUserInfo(data);
        }
      } catch (e) {
        window.debug.error(e);
        reportErrorToSentry(e);
        setError("Error fetching user info");
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.accessToken]);

  const value = useMemo(
    () => ({
      data: { userInfo, activeTenant, tenantOptions },
      isLoading,
      error,
      updateActiveTenant,
      updateActiveTenantWithoutReload,
    }),
    [activeTenant, error, userInfo, isLoading, tenantOptions]
  );

  if (error) {
    return (
      <FullPageError
        errorTitle={error}
        errorDescription="Retry or check back later"
        actionText="Retry"
        action={() => window.location.reload()}
      />
    );
  }

  return (
    <UserInfoContext.Provider value={value}>
      {isLoading && <Loader variant="fullpage" />}
      {children}
    </UserInfoContext.Provider>
  );
}

export default UserInfoProvider;
