//Core imports
import { useCallback, useEffect, useState } from "react";

//Pixel imports
import {
  Button,
  Checklist,
  ChecklistDesc,
  ChecklistItem,
  ChecklistStatus,
  ChecklistTitle,
  Description,
  Loader,
  Message,
  PageTitle,
  PanelCard,
  SandboxSvg,
} from "pixel";

//Helpers
import {
  AutoSetupResponse,
  Configurations,
  FetchKycCheckConfigurationResponse,
  GetPaymentsGetwayResponse,
  KycRequestsConfigurationsResponse,
  OmsTenantSettingsResponse,
} from "@fintechprimitives/fpapi";
import { useFpapi } from "../../../../context/fpapi/useFpapi";
import { reportErrorToSentry } from "../../../../global/sentry/sentry";
import { ConditionalRenderer } from "../../../../utils/helpers";
import { isEmptyObject } from "../../../../utils/utils";

type CheckListStatuses = "CONFIGURED" | "NOT_CONFIGURED" | "ERROR";

type ConfigurationStatusService =
  | "ONBOARDING"
  | "FILES"
  | "ACCOUNTS_MF"
  | "BACKPRESSURE"
  | "KYC_GATEWAY"
  | "KYC_REQUEST"
  | "VERIFICATION"
  | "OMS_SEED"
  | "OMS_RTA"
  | "OMS_AMC"
  | "PG_SEED"
  | "PG_RAZORPAY_GATEWAY"
  | "PG_BILLDESK_GATEWAY"
  | "PG_BSE_GATEWAY"
  | "PG_AMC"
  | "ADVISORY"
  | "BI_TOOLS";

type CheckListStatus = Record<ConfigurationStatusService, CheckListStatuses>;

const isAllRequiredServicesConfigured = (
  checkListStatus: CheckListStatus,
  requiredStatuses: Partial<ConfigurationStatusService>[]
) => {
  let allRequiredServicesConfigured = true;
  for (let status of requiredStatuses) {
    if (checkListStatus[status] !== "CONFIGURED") {
      allRequiredServicesConfigured = false;
      break;
    }
  }

  return allRequiredServicesConfigured;
};

const isAnyErrorInServices = (
  checkListStatus: CheckListStatus,
  requiredStatuses: Partial<ConfigurationStatusService>[]
) => {
  let hasError = false;
  for (const status of requiredStatuses) {
    if (checkListStatus[status] === "ERROR") {
      hasError = true;
      break;
    }
  }

  return hasError;
};

function getOMSConfiguredStatus(checkListStatus: CheckListStatus) {
  const requiredStatuses: Partial<ConfigurationStatusService>[] = [
    "OMS_SEED",
    "ONBOARDING",
    "OMS_RTA",
    "ACCOUNTS_MF",
    "BACKPRESSURE",
    "FILES",
  ];

  const allRequiredServicesConfigured = isAllRequiredServicesConfigured(
    checkListStatus,
    requiredStatuses
  );
  const hasError = isAnyErrorInServices(checkListStatus, requiredStatuses);

  if (allRequiredServicesConfigured) {
    return "success";
  } else if (hasError) {
    return "error";
  } else {
    return "";
  }
}

function getPGConfiguredStatus(checkListStatus: CheckListStatus) {
  const requiredRazorpayStatuses: Partial<ConfigurationStatusService>[] = [
    "PG_SEED",
    "PG_RAZORPAY_GATEWAY",
  ];
  const requiredBilldeskStatuses: Partial<ConfigurationStatusService>[] = [
    "PG_SEED",
    "PG_BILLDESK_GATEWAY",
  ];
  const requiredBseStatuses: Partial<ConfigurationStatusService>[] = ["PG_SEED", "PG_BSE_GATEWAY"];

  const allRequiredServicesConfiguredForRazorpay = isAllRequiredServicesConfigured(
    checkListStatus,
    requiredRazorpayStatuses
  );

  const allRequiredServicesConfiguredForBilldesk = isAllRequiredServicesConfigured(
    checkListStatus,
    requiredBilldeskStatuses
  );

  const allRequiredServicesConfiguredForBse = isAllRequiredServicesConfigured(
    checkListStatus,
    requiredBseStatuses
  );
  const hasRazorpayError = isAnyErrorInServices(checkListStatus, requiredRazorpayStatuses);
  const hasBilldeskError = isAnyErrorInServices(checkListStatus, requiredBilldeskStatuses);
  const hasbseError = isAnyErrorInServices(checkListStatus, requiredBseStatuses);

  if (
    allRequiredServicesConfiguredForRazorpay ||
    allRequiredServicesConfiguredForBilldesk ||
    allRequiredServicesConfiguredForBse
  ) {
    return "success";
  } else if (hasRazorpayError || hasBilldeskError || hasbseError) {
    return "error";
  } else {
    return "";
  }
}

function getAMCsConfiguredStatus(checkListStatus: CheckListStatus) {
  const requiredStatuses: Partial<ConfigurationStatusService>[] = ["OMS_AMC", "PG_AMC"];

  const allRequiredServicesConfigured = isAllRequiredServicesConfigured(
    checkListStatus,
    requiredStatuses
  );
  const hasError = isAnyErrorInServices(checkListStatus, requiredStatuses);

  if (allRequiredServicesConfigured) {
    return "success";
  } else if (hasError) {
    return "error";
  } else {
    return "";
  }
}

function getKYCCheckConfiguredStatus(checkListStatus: CheckListStatus) {
  const requiredStatuses: Partial<ConfigurationStatusService>[] = ["KYC_GATEWAY"];

  const allRequiredServicesConfigured = isAllRequiredServicesConfigured(
    checkListStatus,
    requiredStatuses
  );
  const hasError = isAnyErrorInServices(checkListStatus, requiredStatuses);

  if (allRequiredServicesConfigured) {
    return "success";
  } else if (hasError) {
    return "error";
  } else {
    return "";
  }
}

function getNewKYCRequesetConfiguredStatus(checkListStatus: CheckListStatus) {
  const requiredStatuses: Partial<ConfigurationStatusService>[] = ["KYC_REQUEST", "FILES"];

  const allRequiredServicesConfigured = isAllRequiredServicesConfigured(
    checkListStatus,
    requiredStatuses
  );
  const hasError = isAnyErrorInServices(checkListStatus, requiredStatuses);

  if (allRequiredServicesConfigured) {
    return "success";
  } else if (hasError) {
    return "error";
  } else {
    return "";
  }
}

function getBankVerificationConfiguredStatus(checkListStatus: CheckListStatus) {
  const requiredStatuses: Partial<ConfigurationStatusService>[] = ["VERIFICATION", "FILES"];

  const allRequiredServicesConfigured = isAllRequiredServicesConfigured(
    checkListStatus,
    requiredStatuses
  );
  const hasError = isAnyErrorInServices(checkListStatus, requiredStatuses);

  if (allRequiredServicesConfigured) {
    return "success";
  } else if (hasError) {
    return "error ";
  } else {
    return "";
  }
}

function getAdvisoryConfiguredStatus(checkListStatus: CheckListStatus) {
  const requiredStatuses: Partial<ConfigurationStatusService>[] = ["ADVISORY"];

  const allRequiredServicesConfigured = isAllRequiredServicesConfigured(
    checkListStatus,
    requiredStatuses
  );
  const hasError = isAnyErrorInServices(checkListStatus, requiredStatuses);

  if (allRequiredServicesConfigured) {
    return "success";
  } else if (hasError) {
    return "error ";
  } else {
    return "";
  }
}

function getBiToolsConfiguredStatus(checkListStatus: CheckListStatus) {
  const requiredStatuses: Partial<ConfigurationStatusService>[] = ["BI_TOOLS"];

  const allRequiredServicesConfigured = isAllRequiredServicesConfigured(
    checkListStatus,
    requiredStatuses
  );
  const hasError = isAnyErrorInServices(checkListStatus, requiredStatuses);

  if (allRequiredServicesConfigured) {
    return "success";
  } else if (hasError) {
    return "error ";
  } else {
    return "";
  }
}

function santizeobject(obj: any) {
  return obj ?? {};
}

function isServiceConfigurationObject(obj: any): obj is { status: string; message: string | null } {
  return (
    obj &&
    typeof obj.status === "string" &&
    (obj.message === null || typeof obj.message === "string")
  );
}

function calculateCheckListStatus(
  servicesStatus: Configurations.AutoSetupResponse | undefined,
  servicesBeingConfigured: ConfigurationStatusService[] | undefined,
  servicesConfigurationsResponse: Configurations.AutoSetupResponse | undefined
): CheckListStatus {
  const checkListStatus: CheckListStatus = {
    ONBOARDING: "NOT_CONFIGURED",
    FILES: "NOT_CONFIGURED",
    ACCOUNTS_MF: "NOT_CONFIGURED",
    BACKPRESSURE: "NOT_CONFIGURED",
    KYC_GATEWAY: "NOT_CONFIGURED",
    KYC_REQUEST: "NOT_CONFIGURED",
    VERIFICATION: "NOT_CONFIGURED",
    OMS_SEED: "NOT_CONFIGURED",
    OMS_RTA: "NOT_CONFIGURED",
    OMS_AMC: "NOT_CONFIGURED",
    PG_SEED: "NOT_CONFIGURED",
    PG_RAZORPAY_GATEWAY: "NOT_CONFIGURED",
    PG_BILLDESK_GATEWAY: "NOT_CONFIGURED",
    PG_BSE_GATEWAY: "NOT_CONFIGURED",
    PG_AMC: "NOT_CONFIGURED",
    ADVISORY: "NOT_CONFIGURED",
    BI_TOOLS: "NOT_CONFIGURED",
  };

  for (const [key, value] of Object.entries(santizeobject(servicesStatus))) {
    const service = key as ConfigurationStatusService;
    if (isServiceConfigurationObject(value)) {
      checkListStatus[service] = value?.status as CheckListStatuses;
    }
  }

  if (servicesBeingConfigured && servicesConfigurationsResponse) {
    for (const [key, value] of Object.entries(santizeobject(servicesConfigurationsResponse))) {
      const service = key as ConfigurationStatusService;
      if (isServiceConfigurationObject(value)) {
        const status = value?.status === "NOT_CONFIGURED" ? "ERROR" : "NOT_CONFIGURED";
        checkListStatus[service] = status as CheckListStatuses;
      }
    }
  }

  return checkListStatus;
}

const getKycCheckList = (kycCheck: FetchKycCheckConfigurationResponse) => {
  if (!isEmptyObject(kycCheck)) {
    let kycCheckList = "";
    if (kycCheck?.gateway === "amc-ipru") {
      kycCheckList = "IPRU";
    }

    if (kycCheck?.gateway === "kra-cams") {
      kycCheckList = "CAMS";
    }

    if (kycCheck?.gateway === "kra-cvl") {
      kycCheckList = "CVL";
    }

    return kycCheckList;
  } else {
    return "";
  }
};

const getKycRequestData = (data: KycRequestsConfigurationsResponse) => {
  if (data) {
    if (data?.settings) {
      return "Other AMC";
    } else {
      return "IPRU (Cybrilla credentials)";
    }
  } else {
    return "";
  }
};

const getMfOrderList = (omsSettings: OmsTenantSettingsResponse) => {
  const distributor = omsSettings?.arn_code ?? omsSettings?.ria_code ?? "";

  let rta = [];

  if (omsSettings?.rta_codes?.cams_broker_code) {
    rta.push("Cams");
  }

  if (omsSettings?.rta_codes?.karvy_broker_code) {
    rta.push("Kfintech");
  }

  if (rta?.length) {
    return `${distributor}, RTA: ${rta?.join(", ")}`;
  } else {
    return distributor;
  }
};

const getVerficationData = (verficationData: Object | null, checkListStatus: CheckListStatus) => {
  if (getBankVerificationConfiguredStatus(checkListStatus) === "success") {
    return verficationData ? "Razorpay" : "Cybrilla default";
  } else {
    return "";
  }
};

const getPGData = (pg: GetPaymentsGetwayResponse) => {
  let pgSet = new Set();

  if (pg?.default_payment_gateway) {
    const defaultGateway =
      pg?.default_payment_gateway?.charAt(0)?.toUpperCase() + pg?.default_payment_gateway?.slice(1);
    pgSet.add(defaultGateway);

    if (pg?.providers) {
      pg?.providers?.forEach((item) => {
        const provider = item?.charAt(0)?.toUpperCase() + item?.slice(1);
        pgSet.add(provider);
      });
    }

    return Array.from(pgSet).join(", ");
  } else {
    return "";
  }
};

export default function ConfigurationStatus() {
  const { fpapi } = useFpapi();
  const [servicesResponse, setAllServicesResponse] = useState<AutoSetupResponse | undefined>(
    undefined
  );
  const [error, setError] = useState("");
  const [checkListStatus, setCheckListStatus] = useState<CheckListStatus>(() => {
    return calculateCheckListStatus(servicesResponse, undefined, undefined);
  });

  const [loading, setLoading] = useState(false);

  const fetchAllServiceStatus = useCallback(async () => {
    try {
      setLoading(true);
      const response = await fpapi?.fpDashboardClient?.configurations()?.getSandboxConfiguration();
      setAllServicesResponse(response);
    } catch (e: any) {
      reportErrorToSentry(e);

      setError("Error fetching data. Please try again");
      window.debug.error(e);
    } finally {
      setLoading(false);
    }
  }, [fpapi?.fpDashboardClient]);

  useEffect(() => {
    fetchAllServiceStatus();
  }, [fetchAllServiceStatus]);

  useEffect(() => {
    const latestCheckListStatus = calculateCheckListStatus(servicesResponse, undefined, undefined);
    setCheckListStatus(latestCheckListStatus);
  }, [servicesResponse]);

  return (
    <div>
      <ConditionalRenderer check={loading}>
        <Loader variant="fullpage" />
      </ConditionalRenderer>
      <PageTitle title="Configuration Status" />
      <Description type="text" text="Review your app configurations here" />
      <PanelCard>
        <div className="space-around flex items-center">
          <div className="flex w-5/12 justify-center">
            <SandboxSvg />
          </div>
          <div>
            <Checklist>
              <ChecklistItem>
                <ChecklistStatus status={getOMSConfiguredStatus(checkListStatus)} />
                <ChecklistTitle>MF orders</ChecklistTitle>
                <ChecklistDesc>{getMfOrderList(servicesResponse?.OMS_SEED?.data)}</ChecklistDesc>
              </ChecklistItem>
              <ChecklistItem>
                <ChecklistStatus status={getPGConfiguredStatus(checkListStatus)} />
                <ChecklistTitle>Payments</ChecklistTitle>
                <ChecklistDesc>{getPGData(servicesResponse?.PG_SEED?.data)}</ChecklistDesc>
              </ChecklistItem>
              <ChecklistItem>
                <ChecklistStatus status={getAMCsConfiguredStatus(checkListStatus)} />
                <ChecklistTitle>AMCs</ChecklistTitle>
                <ChecklistDesc>
                  {servicesResponse?.OMS_AMC?.data?.data?.length
                    ? `${servicesResponse?.OMS_AMC?.data?.data?.length} AMCs configured`
                    : ""}
                </ChecklistDesc>
              </ChecklistItem>
              <ChecklistItem>
                <ChecklistStatus status={getKYCCheckConfiguredStatus(checkListStatus)} />
                <ChecklistTitle>KYC Check</ChecklistTitle>
                <ChecklistDesc>
                  {getKycCheckList(servicesResponse?.KYC_GATEWAY?.data) ?? ""}
                </ChecklistDesc>
              </ChecklistItem>
              <ChecklistItem>
                <ChecklistStatus status={getNewKYCRequesetConfiguredStatus(checkListStatus)} />
                <ChecklistTitle>New KYC request</ChecklistTitle>
                <ChecklistDesc>
                  {getKycRequestData(servicesResponse?.KYC_REQUEST?.data)}
                </ChecklistDesc>
              </ChecklistItem>
              <ChecklistItem>
                <ChecklistStatus status={getBankVerificationConfiguredStatus(checkListStatus)} />
                <ChecklistTitle>Bank account verification</ChecklistTitle>
                <ChecklistDesc>
                  {getVerficationData(servicesResponse?.VERIFICATION?.data, checkListStatus)}
                </ChecklistDesc>
              </ChecklistItem>
              <ChecklistItem>
                <ChecklistStatus status={getAdvisoryConfiguredStatus(checkListStatus)} />
                <ChecklistTitle>Advisory</ChecklistTitle>
              </ChecklistItem>
              <ChecklistItem>
                <ChecklistStatus status={getBiToolsConfiguredStatus(checkListStatus)} />
                <ChecklistTitle>BI Tools</ChecklistTitle>
              </ChecklistItem>
            </Checklist>

            <ConditionalRenderer check={Boolean(error)}>
              <div className="flex">
                <Button
                  variant="primary"
                  fullwidth
                  customClass="mr-2"
                  size="sm"
                  onClick={() => window.location.reload()}
                >
                  Retry
                </Button>
              </div>

              <Message type="error" variant="field" title={error} />
            </ConditionalRenderer>
          </div>
        </div>
      </PanelCard>
    </div>
  );
}
