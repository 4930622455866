declare global {
  interface Window {
    debug: {
      log: any;
      warn: any;
      error: any;
    };
  }
}

export const initializeDebugger = () => {
  const isDebug = process.env.REACT_APP_DEBUG === "true";

  if (isDebug && window.console) {
    window.debug = {
      log: window.console.log,
      warn: window.console.warn,
      error: window.console.error,
    };
  } else {
    window.debug = {
      log: function () {
        /*empty*/
      },
      warn: function () {
        /*empty*/
      },
      error: function () {
        /*empty*/
      },
    };
  }
};
