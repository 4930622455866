import { useContext } from "react";
import { UserInfoContext } from "./UserInfoContext";

export const useUserInfo = () => {
  const userInfo = useContext(UserInfoContext);

  if (!userInfo) {
    throw new Error("useUserInfo must be used within a UserInfoProvider");
  }

  return userInfo;
};
