import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Description,
  Menu,
  Modal,
  PageTitle,
  SandboxSvg,
  WidgetList,
} from "pixel";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useFpapi } from "../packages/context/fpapi/useFpapi";
import { Role, Roles } from "../packages/context/userinfo/types";
import { useUserInfo } from "../packages/context/userinfo/useUserInfo";
import { reportErrorToSentry } from "../packages/global/sentry/sentry";
import {
  OLD_DASHBOARD_LINK,
  PARTNER_FLOW_ENABLED_TENANTS,
  THIRDEYE_NAVIGATION_URL,
  THIRDEYE_WIAM_CLIENT_ID,
  VALID_THIRDEYE_ROLES,
} from "../packages/utils/constants";
import { ConditionalRenderer } from "../packages/utils/helpers";
import {
  ProtectedExternalLink,
  ProtectedExternalLinkButton,
  ProtectedMenu,
  ProtectedMenuItem,
} from "../packages/utils/routeHelpers";
import {
  getLast30MinsDateRangeStr,
  partnerSelectedFolioSessionStorage,
  partnerSelectedSchemeSessionStorage,
} from "../packages/utils/utils";
import FpConsoleNavItems from "./FpConsoleNavItems";

type NavGroupItem =
  | "OPERATIONS"
  | "CONFIGURATIONS"
  | "DEVELOPERS"
  | "FPACCOUNT"
  | "PARTNERS"
  | "ROBOX_PARTNER_FLOW"
  | "ORDERS"
  | "PAYMENTS"
  | "INVESTORS"
  | "KYC";

interface NavItemsProps {
  handleSideBar: (param: boolean) => void;
  isSideBarCollapsed: boolean;
}

const NavItems = ({ handleSideBar, isSideBarCollapsed }: NavItemsProps) => {
  const { fpapi } = useFpapi();
  const userInfo = useUserInfo();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const activeTenant = userInfo?.data.activeTenant;

  const location = useLocation();
  const opsPortalRedirectUrl = `${OLD_DASHBOARD_LINK}login?realm=${
    userInfo?.data.userInfo?.type === "internal" ? "root" : activeTenant
  }&tenant=${userInfo?.data.activeTenant}`;

  const hasValidRolesToAccessThirdeye = VALID_THIRDEYE_ROLES.some((role) =>
    userInfo?.data?.userInfo?.roles?.includes(role as Role)
  );
  const thirdeyeRedirectURLObject = new URL(
    THIRDEYE_NAVIGATION_URL.replace("{{realm}}", userInfo?.data.activeTenant ?? "")
  );
  if (userInfo?.data.userInfo?.type === "internal") {
    thirdeyeRedirectURLObject.searchParams.append("internal", "true");
  }
  const thirdeyeRedirectUrl = thirdeyeRedirectURLObject.toString();

  const matchCurrentPath = (requiredRoute: string) => {
    const currentPath = location.pathname;
    return currentPath.includes(requiredRoute);
  };

  const [expanded, setExpanded] = useState<NavGroupItem | false>(() => {
    const currentPathToGroupMapping = {
      "/operations": "OPERATIONS",
      "/configurations": "CONFIGURATIONS",
      "/developers": "DEVELOPERS",
      "/fp-account": "FPACCOUNT",
      "/partners": "PARTNERS",
      "/orders": "ORDERS",
      "/investors": "INVESTORS",
      "/kyc": "KYC",
    } as const;

    let navGroupItem: NavGroupItem | false = false;

    let key: keyof typeof currentPathToGroupMapping;
    for (key in currentPathToGroupMapping) {
      if (matchCurrentPath(key)) {
        navGroupItem = currentPathToGroupMapping[key];
      }
    }

    return navGroupItem;
  });

  const handleAccordionChange = (panel: NavGroupItem) => {
    return (e: React.MouseEvent<HTMLDivElement, MouseEvent>, isExpanded: boolean) => {
      const shouldExpand = isExpanded || isSideBarCollapsed;
      setExpanded(shouldExpand ? panel : false);
      handleSideBar(false);
    };
  };

  const isFpConsoleActive = matchCurrentPath("/fp-console");

  const apiLogsUrlSearchParams = new URLSearchParams({
    fetch_count: "20",
    status: "500,502",
    date_range: getLast30MinsDateRangeStr(),
  }).toString();

  const handleShouldOpenThirdeyeLink = async () => {
    try {
      setIsModalOpen(false);

      const [tenantconfigurationdata, wiamClients] = await Promise.all([
        fpapi?.fpDashboardClient?.data_platform()?.fetchTenant(),
        fpapi?.fpDashboardClient?.access_management().listWiamClients(`fpa_${activeTenant}`),
      ]);

      const isDataPlatformConfigured = tenantconfigurationdata?.is_onboarded;
      const isThirdeyeClientConfigured = wiamClients?.data?.findIndex(
        (wiamClient) => wiamClient.clientId === THIRDEYE_WIAM_CLIENT_ID
      );

      if (isDataPlatformConfigured && isThirdeyeClientConfigured) {
        return true;
      } else {
        setIsModalOpen(true);
        return false;
      }
    } catch (e) {
      reportErrorToSentry(e);
      setIsModalOpen(true);
      return false;
    }
  };

  const handleClickOnInvest = () => {
    partnerSelectedFolioSessionStorage.remove();
    partnerSelectedSchemeSessionStorage.remove();
    sessionStorage.removeItem("investment_workflow_order_id");
    sessionStorage.removeItem("investment_workflow_stage");
    window.location.reload();
  };

  return isFpConsoleActive ? (
    <FpConsoleNavItems isSideBarCollapsed={isSideBarCollapsed} />
  ) : (
    <>
      <ProtectedMenu matchRoute="/operations">
        <div className="mb-4">
          {!isSideBarCollapsed && <PageTitle variant="group" title="OPS" />}

          <Accordion
            expanded={expanded === "OPERATIONS"}
            onChange={handleAccordionChange("OPERATIONS")}
          >
            <AccordionSummary iconName="Operation" active={expanded === "OPERATIONS"}>
              Operations
            </AccordionSummary>
            <div className="collapse-menu-title">Operations</div>
            <AccordionDetails>
              <WidgetList>
                <ProtectedMenuItem
                  redirectTo="/operations/order-operations"
                  label="Order operations"
                />
                <ProtectedMenuItem redirectTo="/operations/aging-orders" label="Aging orders" />
                <ProtectedMenuItem redirectTo="/operations/transactions" label="Transactions" />
                <ProtectedMenuItem redirectTo="/operations/transfers" label="Payment transfers" />
                <ProtectedMenuItem redirectTo="/operations/folios" label="Folios" />
                <ProtectedMenuItem redirectTo={`/operations/fund-schemes`} label="Fund schemes" />
              </WidgetList>
            </AccordionDetails>
          </Accordion>
        </div>
      </ProtectedMenu>

      <ProtectedMenu matchRoute="/orders">
        <div className="mb-4">
          {!isSideBarCollapsed && <PageTitle variant="group" title="ORDERS" />}

          <Accordion expanded={expanded === "ORDERS"} onChange={handleAccordionChange("ORDERS")}>
            <AccordionSummary iconName="Emandate" active={expanded === "ORDERS"}>
              Orders
            </AccordionSummary>

            <div className="collapse-menu-title">Orders</div>

            <AccordionDetails>
              <WidgetList>
                <ProtectedMenuItem redirectTo="/orders/purchases" label="Purchases" />
                <ProtectedMenuItem redirectTo="/orders/purchase-plans" label="Purchase plans" />
                <ProtectedMenuItem redirectTo="/orders/redemptions" label="Redemptions" />
                <ProtectedMenuItem redirectTo="/orders/redemption-plans" label="Redemption plans" />
                <ProtectedMenuItem redirectTo="/orders/switches" label="Switches" />
                <ProtectedMenuItem redirectTo="/orders/switch-plans" label="Switch plans" />
              </WidgetList>
            </AccordionDetails>
          </Accordion>
        </div>
      </ProtectedMenu>

      <ProtectedMenu matchRoute="/payments">
        <div className="mb-4">
          {!isSideBarCollapsed && <PageTitle variant="group" title="PAYMENTS" />}

          <Accordion
            expanded={expanded === "PAYMENTS"}
            onChange={handleAccordionChange("PAYMENTS")}
          >
            <AccordionSummary iconName="Netbanking" active={expanded === "PAYMENTS"}>
              Payments
            </AccordionSummary>

            <div className="collapse-menu-title">Payments</div>

            <AccordionDetails>
              <WidgetList>
                <ProtectedMenuItem redirectTo="/payments" label="Payments" />
                <ProtectedMenuItem redirectTo="/mandates" label="Mandates" />
              </WidgetList>
            </AccordionDetails>
          </Accordion>
        </div>
      </ProtectedMenu>

      <ProtectedMenu matchRoute="/investors">
        <div className="mb-4">
          {!isSideBarCollapsed && <PageTitle variant="group" title="INVESTORS" />}

          <Accordion
            expanded={expanded === "INVESTORS"}
            onChange={handleAccordionChange("INVESTORS")}
          >
            <AccordionSummary iconName="ContactOutlined" active={expanded === "INVESTORS"}>
              Investors
            </AccordionSummary>

            <div className="collapse-menu-title">INVESTORS</div>

            <AccordionDetails>
              <WidgetList>
                <ProtectedMenuItem
                  redirectTo="/investors/investment_accounts"
                  label="Investment accounts"
                />

                <ProtectedMenuItem
                  redirectTo="/investors/investors-overview"
                  label="Investors overview"
                />

                <ProtectedMenuItem
                  redirectTo="/investors/non-individual-investors"
                  label="Non individual investors"
                />

                <ProtectedMenuItem redirectTo="/investors/kyc_requests" label="KYC requests" />
              </WidgetList>
            </AccordionDetails>
          </Accordion>
        </div>
      </ProtectedMenu>

      <ProtectedMenu matchRoute="/partners">
        <div className="mb-4">
          {!isSideBarCollapsed && <PageTitle variant="group" title="PARTNERS" />}

          <Accordion
            expanded={expanded === "PARTNERS"}
            onChange={handleAccordionChange("PARTNERS")}
          >
            <AccordionSummary iconName="UserFilled" active={expanded === "PARTNERS"}>
              Partners
            </AccordionSummary>

            <div className="collapse-menu-title">Partners</div>

            <AccordionDetails>
              <WidgetList>
                <ProtectedMenuItem redirectTo="/partners" label="Partner management" />
              </WidgetList>
            </AccordionDetails>
          </Accordion>
        </div>
      </ProtectedMenu>

      <ProtectedMenu matchRoute="/developers">
        <div className="mb-4">
          {!isSideBarCollapsed && <PageTitle variant="group" title="LOGS" />}

          <Accordion
            expanded={expanded === "DEVELOPERS"}
            onChange={handleAccordionChange("DEVELOPERS")}
          >
            <AccordionSummary iconName="Developer" active={expanded === "DEVELOPERS"}>
              Developers
            </AccordionSummary>
            <div className="collapse-menu-title">Developers</div>
            <AccordionDetails>
              <WidgetList>
                <ProtectedMenuItem
                  redirectTo={`/developers/api-logs?${apiLogsUrlSearchParams}`}
                  label="API Logs"
                />
                <ProtectedMenuItem redirectTo={`/developers/events`} label="Events" />
              </WidgetList>
            </AccordionDetails>
          </Accordion>
        </div>
      </ProtectedMenu>

      <ProtectedMenu matchRoute="/fp-account">
        <div className="mb-4">
          {!isSideBarCollapsed && <PageTitle variant="group" title="ACCOUNT CONFIGURATIONS" />}

          <Accordion
            expanded={expanded === "FPACCOUNT"}
            onChange={handleAccordionChange("FPACCOUNT")}
          >
            <AccordionSummary iconName="UserProfile" active={expanded === "FPACCOUNT"}>
              FP account
            </AccordionSummary>
            <div className="collapse-menu-title">FP account</div>
            <AccordionDetails>
              <WidgetList>
                <ProtectedMenuItem
                  redirectTo={`/fp-account/configuration-status`}
                  label="Configuration status"
                />
                <ProtectedMenuItem redirectTo={"/fp-account/fp-assist"} label="FP Assist" />
                <ProtectedMenuItem redirectTo={`/fp-account/mf_services`} label="MF orders" />
                <ProtectedMenuItem redirectTo={`/fp-account/payments_gateway`} label="Payments" />
                <ProtectedMenuItem redirectTo={`/fp-account/amcs`} label="AMCs" />

                <ProtectedMenuItem
                  redirectTo={`/fp-account/verifications`}
                  label="Verification services"
                />
                <ProtectedMenuItem redirectTo={`/fp-account/kyc-services`} label="KYC services" />
                <ProtectedMenuItem redirectTo={`/fp-account/advisory`} label="Advisory" />
                <ProtectedMenuItem
                  redirectTo={`/fp-account/bi-tool`}
                  label="Business intelligence tool"
                />

                <ProtectedMenuItem
                  redirectTo={`/fp-account/user_management`}
                  label="User management"
                />
                <ProtectedMenuItem
                  redirectTo={`/fp-account/scheduled-queries`}
                  label="Scheduled queries"
                />
                <ProtectedMenuItem
                  redirectTo={`/fp-account/notification-webhooks`}
                  label="Notification webhooks"
                />
              </WidgetList>
            </AccordionDetails>
          </Accordion>
        </div>
      </ProtectedMenu>

      <ConditionalRenderer check={PARTNER_FLOW_ENABLED_TENANTS.includes(activeTenant ?? "")}>
        <ProtectedMenu matchRoute="/partner">
          <div className="mb-4">
            <Accordion
              expanded={expanded === "ROBOX_PARTNER_FLOW"}
              onChange={handleAccordionChange("ROBOX_PARTNER_FLOW")}
            >
              <AccordionSummary iconName="Developer" active={expanded === "ROBOX_PARTNER_FLOW"}>
                Transact
              </AccordionSummary>
              <AccordionDetails>
                <WidgetList>
                  <div onClick={() => handleClickOnInvest()}>
                    <ProtectedMenuItem
                      redirectTo={`/partner/assisted-checkout?isPartner=true`}
                      label="Invest"
                    />
                  </div>
                </WidgetList>
              </AccordionDetails>
            </Accordion>
          </div>
        </ProtectedMenu>
      </ConditionalRenderer>

      <div className="mb-4">
        <ConditionalRenderer
          check={Boolean(
            !isSideBarCollapsed &&
              userInfo?.data.userInfo?.tenants &&
              userInfo?.data.userInfo?.tenants.length > 0 &&
              !userInfo?.data?.userInfo?.roles?.includes(Role.TENANT_PARTNER)
          )}
        >
          <PageTitle variant="group" title="External Applications" />
        </ConditionalRenderer>

        <Menu>
          <ProtectedExternalLink
            label="FP Console"
            link={opsPortalRedirectUrl}
            allowedRoles={[
              Role.CYBRILLA_ADMIN,
              Role.CUSTOMER_SUCCESS,
              Role.OPERATIONS,
              Role.TENANT_OPERATIONS,
              Role.TENANT_ROOT,
              Role.TENANT_WORKFORCE,
            ]}
          />

          <ConditionalRenderer check={Boolean(hasValidRolesToAccessThirdeye)}>
            <ProtectedExternalLinkButton
              label="Thirdeye"
              link={thirdeyeRedirectUrl}
              shouldOpenLinkHandler={handleShouldOpenThirdeyeLink}
              allowedRoles={VALID_THIRDEYE_ROLES as Roles}
            />
          </ConditionalRenderer>
        </Menu>
      </div>
      <Modal
        variant="curvedEdge"
        isOpen={isModalOpen}
        toggleModal={(val: boolean) => {
          setIsModalOpen(false);
        }}
        fullWidth
        size="md"
      >
        <div>
          <section className="text-center">
            <div className="mb-4 flex justify-center">
              <SandboxSvg />
            </div>
            <PageTitle
              title="Thirdeye configuration is not complete"
              semiBold={true}
              customClass="flex justify-center"
            />
            <Description
              type="text"
              text="Please ensure thirdye configuration is complete and wiam clients are enabled"
            />
          </section>
        </div>
      </Modal>
    </>
  );
};
export default NavItems;
