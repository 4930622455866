//Pixel imports
import { CollapseButton, Content, DashboardLayout, HamburgerButton, Menu, Sidebar } from "pixel";

import "pixel/styles/ag-grid-pixel.css";
import "pixel/styles/rs-picker.css";

//Helpers imports
import { PropsWithChildren, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Feedback } from "../packages";
import DateTime from "../packages/components/display/DateTime";
import FullPageError from "../packages/components/display/FullPageError";
import { useUserInfo } from "../packages/context/userinfo/useUserInfo";
import useUIState from "../packages/hooks/useUIState";
import { BASE_URL } from "../packages/utils/constants";
import {
  hasVisitedConsoleSessionStorage,
  hasVisitedInvestSessionStorage,
} from "../packages/utils/utils";
import MenuFooter from "./MenuFooter";
import MenuHeader from "./MenuHeader";
import MenuHeaderLogo from "./MenuHeaderLogo";
import NavItems from "./NavItems";
import SandBoxTag from "./SandBoxTag";
import {
  getHeaderClass,
  getNavbarClass,
  getSideBarCollapsedClass,
  getSpacingMobileState,
  isFirstNavigationToConsole,
  isFirstNavigationToInvest,
  shouldRedirectToConsole,
  shouldRedirectToInvestment,
} from "./dashboardMasterLayoutUtils";

const DashboardMasterLayout = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const navigate = useNavigate();

  const userInfo = useUserInfo();
  const { isMobileMenuCollapsed, isSideBarCollapsed, handleToggleMobileMenu, handleToggleSidebar } =
    useUIState();

  const noTenantsAssigned = userInfo?.data.userInfo?.tenants.length === 0;

  useEffect(() => {
    if (shouldRedirectToConsole(location, userInfo)) {
      // user landed on homepage for the first time and has right permissions to access console
      // redirect to console
      navigate("/fp-console");
      hasVisitedConsoleSessionStorage.set("true");
    } else if (isFirstNavigationToConsole(location, userInfo)) {
      // user reaches console via URL and has right permissions
      // store the visited status so that if user goes back to homepage and reloads he does not land in console again
      hasVisitedConsoleSessionStorage.set("true");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.data.userInfo?.roles]);

  useEffect(() => {
    if (shouldRedirectToInvestment(location, userInfo)) {
      // user landed on homepage for the first time and has right permissions to access console
      // redirect to invest
      navigate("/partner/assisted-checkout?isPartner=true");
      hasVisitedInvestSessionStorage.set("true");
    } else if (isFirstNavigationToInvest(location, userInfo)) {
      // user reaches invest via URL and has right permissions
      // store the visited status so that if user goes back to homepage and reloads he does not land in invest again
      hasVisitedInvestSessionStorage.set("true");
    }
  }, [location, navigate, userInfo]);

  const toggleSidebar = () => {
    handleToggleSidebar(!isSideBarCollapsed);
  };

  return (
    <DashboardLayout isClosed={isSideBarCollapsed} isMobileClosed={isMobileMenuCollapsed}>
      <Sidebar>
        <CollapseButton onClick={toggleSidebar} />
        <header className={getHeaderClass(isSideBarCollapsed)}>
          <div
            className={`mb-3 hidden items-center md:flex ${getSideBarCollapsedClass(
              isSideBarCollapsed,
              "justify-center"
            )} `}
          >
            <MenuHeaderLogo />

            <div className="header-menu-selector flex items-center">
              <div>
                <MenuHeader />
              </div>
            </div>
          </div>

          <SandBoxTag />

          <DateTime />
        </header>

        <nav className={getNavbarClass(isSideBarCollapsed)}>
          <Menu>
            <NavItems
              handleSideBar={(collapsed: boolean) => handleToggleSidebar(collapsed)}
              isSideBarCollapsed={isSideBarCollapsed}
            />
          </Menu>
        </nav>

        <MenuFooter />
      </Sidebar>

      <Content spacingMobile={getSpacingMobileState(location)}>
        <div className="mx-auto 2xl:max-w-screen-2xl">
          <div className="dark:bg-neutral-dark-0 dark:border-neutral-dark-20 bg-neutral-0 shadow-4xl fixed top-0 left-0 right-0 z-50 flex items-center px-6 py-2 dark:border-b dark:border-solid dark:shadow-transparent md:hidden">
            <div className="mr-6">
              <HamburgerButton onClick={() => handleToggleMobileMenu(!isMobileMenuCollapsed)} />
            </div>

            <div className="mr-4">
              <MenuHeaderLogo />
            </div>

            <div className="md:hiddden header-menu-selector flex items-center">
              <div>
                <MenuHeader />
              </div>
            </div>
          </div>

          {noTenantsAssigned ? (
            <FullPageError
              errorTitle="Unable to fetch data"
              errorDescription="No tenants assigned to the user"
            />
          ) : (
            children
          )}
        </div>

        <Feedback />

        <footer className="absolute bottom-0 left-0 right-0 flex justify-end py-4 pl-6 pr-10">
          <span className="text-size-12 mr-1">Powered by</span>
          <img src={`${BASE_URL}/footer-logo.svg`} alt="" />
        </footer>
      </Content>
    </DashboardLayout>
  );
};
export default DashboardMasterLayout;
