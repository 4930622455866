import { Fp, fp } from "@fintechprimitives/fpapi";
import { UserType } from "./types";

export interface PartialConfig {
  userType: UserType;
  fpApiBaseURL: string | undefined;
  fpDashboardApiBaseURL: string | undefined;
  admin?: {
    accessToken: string | null;
  };
  activeTenant: string | null;
}

export interface Config extends PartialConfig {
  fpApiBaseURL: string;
  fpDashboardApiBaseURL: string;
  admin: {
    accessToken: string;
  };
}

class FpApi {
  // Donot use this constructor direcly. Use the static build methods below
  // Why? https://dev.to/somedood/the-proper-way-to-write-async-constructors-in-javascript-1o8c
  fpApiBaseURL: string;
  fpDashboardApiBaseURL: string;
  fpClient?: Fp;
  fpDashboardClient?: Fp;
  adminToken: string;
  adminClient: Fp | null;
  activeTenant: string | null;

  private constructor(config: Config) {
    this.fpApiBaseURL = config.fpApiBaseURL;
    this.fpDashboardApiBaseURL = config.fpDashboardApiBaseURL;
    this.adminToken = config.admin?.accessToken;
    this.activeTenant = config.activeTenant;
    this.adminClient = null;
  }

  static async buildWithAdminToken(config: Config) {
    const fpapi = new FpApi(config);

    fpapi.fpClient = new fp({
      hostUrl: fpapi.fpApiBaseURL,
      token: fpapi.adminToken,
    });

    const fpDashboardClientOptions: ConstructorParameters<typeof fp>[0] = {
      hostUrl: fpapi.fpDashboardApiBaseURL,
      token: fpapi.adminToken,
    };
    if (fpapi.activeTenant) {
      fpDashboardClientOptions["headers"] = { "x-tenant-name": fpapi.activeTenant };
    }

    fpapi.fpDashboardClient = new fp(fpDashboardClientOptions);

    fpapi.adminClient = new fp({
      hostUrl: fpapi.fpApiBaseURL,
      token: fpapi.adminToken,
    });

    return fpapi;
  }
}
export default FpApi;
