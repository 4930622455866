//Core imports
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

//Functional imports
import App from "./App";

//Helpers imports
import { FpDashboardInit } from "./packages";
import { BASE_URL } from "./packages/utils/constants";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <BrowserRouter basename={BASE_URL}>
    <FpDashboardInit>
      <App />
    </FpDashboardInit>
  </BrowserRouter>
);
