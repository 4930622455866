export const hasAuthParams = (location = window.location): boolean => {
  // response_mode: query
  let searchParams = new URLSearchParams(location.search);
  if (
    (searchParams.get("code") || searchParams.get("error")) &&
    searchParams.get("state")
  ) {
    return true;
  }

  // response_mode: fragment
  searchParams = new URLSearchParams(location.hash.replace("#", "?"));
  if (
    (searchParams.get("code") || searchParams.get("error")) &&
    searchParams.get("state")
  ) {
    return true;
  }

  return false;
};

export const getFormattedJSONString = (value: string) => {
  try {
    const parsedRequestBody = JSON.parse(value);
    const stringifiedRequestBody = JSON.stringify(parsedRequestBody, null, 2);

    return stringifiedRequestBody;
  } catch (error) {
    if (typeof value === "string") {
      return value;
    }else{
      return "";
    }
  }
};
