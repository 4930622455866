import { i18n } from "@lingui/core";
import { messages as enMessages } from "../../locales/en/messages";
import { messages as hiMessages } from "../../locales/hi/messages";

export const defaultLocale = "en";

export function dynamicActivate() {
  i18n.load({
    en: enMessages,
    hi: hiMessages,
  });
  i18n.activate(defaultLocale);
}
