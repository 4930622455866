import { Button, ErrorCrossSvg, Panel } from "pixel";
import { MouseEvent } from "react";

type UnauthorisedPageProps = {
  errorTitle: string;
  errorDescription?: string;
  action?: (e: MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  actionText?: string;
};

const UnauthorisedPage = ({
  errorTitle,
  errorDescription,
  action,
  actionText,
}: UnauthorisedPageProps) => {
  return (
    <Panel>
      <div
        className="flex flex-col items-center justify-center"
        style={{ minHeight: "80vh" }}
      >
        <div className="mb-4">
          <ErrorCrossSvg />
        </div>

        <h2 className="mb-4">{errorTitle}</h2>

        {errorDescription ? <p className="mb-4">{errorDescription}</p> : null}

        {action ? (
          <Button variant="text" onClick={action}>
            {actionText}
          </Button>
        ) : null}
      </div>
    </Panel>
  );
};
export default UnauthorisedPage;
