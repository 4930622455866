import { Location } from "react-router-dom";
import { UserInfoContextType } from "../packages/context/userinfo/UserInfoContext";
import { Role } from "../packages/context/userinfo/types";
import { getMenuRoles } from "../packages/utils/routeAccess";
import {
  hasVisitedConsoleSessionStorage,
  hasVisitedInvestSessionStorage,
} from "../packages/utils/utils";

export const getSideBarCollapsedClass = (isSideBarCollapsed: boolean, classes: string) => {
  return isSideBarCollapsed ? classes : "";
};

export const getNavbarClass = (isSideBarCollapsed: boolean) =>
  isSideBarCollapsed
    ? `sidebar-nav overflow-y-auto pb-5`
    : `sidebar-nav overflow-y-auto px-6 pb-10`;

export const getHeaderClass = (isSideBarCollapsed: boolean) =>
  isSideBarCollapsed ? `header pb-4` : `header pb-4 px-6`;

export const getSpacingMobileState = (location: Location<any>) => {
  const isSpaceNeeded = location?.pathname?.split("/")?.filter(Boolean)?.[0] !== "partner";
  return isSpaceNeeded;
};

const fpConsoleEnabledRoles = getMenuRoles("/fp-console");

const isAuthorisedButNotVisitedConsole = (userInfo: UserInfoContextType | null) => {
  return (
    hasVisitedConsoleSessionStorage.get() !== "true" &&
    userInfo?.data.userInfo?.roles?.some((role) => fpConsoleEnabledRoles.includes(role))
  );
};

const isAuthorisedButNotVisitedInvest = (userInfo: UserInfoContextType | null) => {
  return (
    hasVisitedInvestSessionStorage.get() !== "true" &&
    userInfo?.data?.userInfo?.roles?.includes(Role.TENANT_PARTNER)
  );
};

export const shouldRedirectToConsole = (
  location: Location<any>,
  userInfo: UserInfoContextType | null
) => {
  return location.pathname === "/" && isAuthorisedButNotVisitedConsole(userInfo);
};

export const shouldRedirectToInvestment = (
  location: Location<any>,
  userInfo: UserInfoContextType | null
) => {
  return location.pathname === "/" && isAuthorisedButNotVisitedInvest(userInfo);
};

export const isFirstNavigationToConsole = (
  location: Location<any>,
  userInfo: UserInfoContextType | null
) => {
  return location.pathname === "/fp-console" && isAuthorisedButNotVisitedConsole(userInfo);
};

export const isFirstNavigationToInvest = (
  location: Location<any>,
  userInfo: UserInfoContextType | null
) => {
  return (
    location.pathname === "/partner/assisted-checkout" && isAuthorisedButNotVisitedInvest(userInfo)
  );
};
