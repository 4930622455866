import { Role } from "../../../context/userinfo/types";
import { Filters } from "./types";

export const filterNameDisplayLabelMapping: Partial<Record<keyof Filters, string>> = {
  type: "Event type",
  fp_object_id: "FP object id",
  date_range: "Date range",
};

export const EVENTS_AUTHORISED_ROLES = [
  Role.CUSTOMER_SUCCESS,
  Role.CYBRILLA_ADMIN,
  Role.TENANT_ROOT,
];
