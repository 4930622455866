//Core imports
import { Config, UserManagementObject } from "@fintechprimitives/fpapi";
import { FpAppsInit } from "fpapps/FpAppsInit";
import { Loader } from "pixel";
import { ReactNode, useCallback, useEffect, useState } from "react";

//Helpers
import FullPageError from "../../components/display/FullPageError";
import NotFoundPage from "../../components/display/NotFoundPage";
import { reportErrorToSentry } from "../../global/sentry/sentry";
import { PARTNER_FLOW_ENABLED_TENANTS } from "../../utils/constants";
import { useFpapi } from "../fpapi/useFpapi";
import { useUserInfo } from "../userinfo/useUserInfo";
import FpApiRoboxWrapper from "./fpapi";

interface Props {
  children: ReactNode;
}

function RoboxAppInit({ children }: Readonly<Props>) {
  const { fpapi } = useFpapi();
  const userInfo = useUserInfo();
  const activeTenant = userInfo?.data.activeTenant;
  const [tenantConfig, setTenantConfig] = useState<Config>();
  const [initializedFpapi, setInitializedFpapi] = useState<FpApiRoboxWrapper | null | undefined>(
    null
  );
  const [amcsConfig, setAmcsConfig] = useState<Record<string, string>>();
  const [bankConfig, setBankConfig] = useState<Record<string, string>>();
  const [partnerUser, setPartnerUser] = useState<UserManagementObject>();
  const [nonPartnerUser, setNonPartnerUser] = useState<UserManagementObject>();
  const [error, setError] = useState<string | null>(null);

  const isReady =
    tenantConfig && amcsConfig && bankConfig && partnerUser && nonPartnerUser && initializedFpapi;

  const fetchTenantConfig = useCallback(async () => {
    const config = await fpapi?.fpDashboardClient?.tenant_configs()?.fetch();
    setTenantConfig(config);
  }, [fpapi?.fpDashboardClient]);

  const fetchAmcsConfig = useCallback(async () => {
    const amcsConfigForDashboard = await fpapi?.fpDashboardClient
      ?.tenant_assets()
      ?.fetchAmcsConfig();
    setAmcsConfig(amcsConfigForDashboard);
  }, [fpapi?.fpDashboardClient]);

  const fetchBankConfig = useCallback(async () => {
    const bankConfigForDashboard = await fpapi?.fpDashboardClient
      ?.tenant_assets()
      ?.fetchBankConfig();
    setBankConfig(bankConfigForDashboard);
  }, [fpapi?.fpDashboardClient]);

  const fetchPartnerUser = useCallback(async () => {
    if (userInfo?.data?.userInfo?.fp_identifier) {
      const userRes = await fpapi?.fpDashboardClient
        ?.user_management()
        ?.fetchTenantUserById(
          `fpa_${activeTenant}`,
          userInfo?.data?.userInfo?.fp_identifier,
          userInfo?.data?.userInfo?.type
        );
      setPartnerUser(userRes);
      setNonPartnerUser(userRes);
    }
  }, [
    fpapi?.fpDashboardClient,
    userInfo?.data?.userInfo?.fp_identifier,
    userInfo?.data?.userInfo?.type,
  ]);

  const buildRoboxFpapi = useCallback(async () => {
    const fpapiBuild = fpapi ? await FpApiRoboxWrapper.build(fpapi) : null;
    setInitializedFpapi(fpapiBuild);
  }, [fpapi]);

  useEffect(() => {
    (async () => {
      try {
        if (activeTenant) {
          await Promise.all([
            buildRoboxFpapi(),
            fetchTenantConfig(),
            fetchAmcsConfig(),
            fetchBankConfig(),
            fetchPartnerUser(),
          ]);
        }
      } catch (e) {
        window.debug.error(e);
        setError("Error fetching config");
        reportErrorToSentry(e);
      }
    })();
  }, [
    fetchTenantConfig,
    fpapi,
    fetchAmcsConfig,
    fetchBankConfig,
    buildRoboxFpapi,
    fetchPartnerUser,
    activeTenant,
  ]);

  const render = () => {
    if (error) {
      return (
        <FullPageError
          errorTitle={error}
          errorDescription="Retry or check back later"
          actionText="Retry"
          action={() => window.location.reload()}
        />
      );
    } else if (PARTNER_FLOW_ENABLED_TENANTS.includes(activeTenant ?? "") && isReady) {
      return (
        <FpAppsInit
          userType={"PARTNER"}
          override={true}
          initializedFpapi={initializedFpapi}
          loggedInPartnerID={partnerUser?.annexure?.fp_identifier ?? nonPartnerUser?.id}
          loggedInPartnerLicenceCode={partnerUser?.annexure?.license_no ?? nonPartnerUser?.id}
          externalTenantConfig={tenantConfig}
          externalAmcs={amcsConfig}
          externalBanks={bankConfig}
          tenant={activeTenant ?? ""}
        >
          <section className="flex flex-1 flex-col px-8">{children}</section>
        </FpAppsInit>
      );
    } else if (PARTNER_FLOW_ENABLED_TENANTS.includes(activeTenant ?? "") && !isReady) {
      return <Loader variant="fullpage" />;
    } else {
      return <NotFoundPage />;
    }
  };

  return render();
}

export default RoboxAppInit;
