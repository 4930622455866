import React, { ReactNode } from "react";
import { Roles } from "../context/userinfo/types";
import { useUserInfo } from "../context/userinfo/useUserInfo";
import { isAuthorisedRole } from "./utils";

const ConditionalRenderer = ({
  check,
  children,
}: {
  readonly check: boolean;
  readonly children: ReactNode;
}) => {
  if (!check) return <></>;

  return <>{children}</>;
};

export const withNullCheck = <P extends Record<string, unknown>>(
  WrappedComponent: React.ComponentType<P>,
  check: boolean
) => {
  return (props: P) => {
    if (Boolean(check)) {
      return <WrappedComponent {...props} />;
    }
    return <></>;
  };
};

const TernaryRenderer = ({
  check,
  whenTrue,
  whenFalse,
}: {
  check: boolean;
  whenTrue: ReactNode;
  whenFalse: ReactNode;
}) => {
  return <>{check ? whenTrue : whenFalse}</>;
};

const RenderByUserRole = ({
  allowedRoles,
  children,
}: {
  readonly allowedRoles: Roles;
  readonly children: ReactNode;
}) => {
  const userInfo = useUserInfo();
  const currentRoles = userInfo?.data.userInfo?.roles;
  return (
    <ConditionalRenderer check={isAuthorisedRole(allowedRoles, currentRoles)}>
      {children}
    </ConditionalRenderer>
  );
};

export { ConditionalRenderer, TernaryRenderer, RenderByUserRole };
