import { Loader, PageTitle, SearchAutocomplete, Toast } from "pixel";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useFpapi } from "../packages/context/fpapi/useFpapi";
import { useUserInfo } from "../packages/context/userinfo/useUserInfo";
import { reportErrorToSentry } from "../packages/global/sentry/sentry";
import {
  DASHBOARD_BACKEND_CLIENT_ID,
  DASHBOARD_BACKEND_CLIENT_NAME,
} from "../packages/utils/constants";
import { ConditionalRenderer } from "../packages/utils/helpers";
import { getOptionsIndex, toTitleCase } from "../packages/utils/utils";
import { matchCurrentPath } from "./utils";

const MenuHeader = () => {
  const userInfo = useUserInfo();
  const location = useLocation();
  const { fpapi } = useFpapi();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const tenantOptions = userInfo?.data.tenantOptions;
  const activeTenant = userInfo?.data.activeTenant;
  const userHasAccessToSingleTenantOnly = tenantOptions?.length === 1;
  const noTenantsAssigned = userInfo?.data.userInfo?.tenants.length === 0;
  const isFpConsoleActive = matchCurrentPath(location, "/fp-console");

  const createBackendClient = async (name: string) => {
    const fpAuthClients = await fpapi?.fpDashboardClient
      ?.access_management()
      .listFpAuthClients(name);

    const existingClient = fpAuthClients?.data.find(
      (fpAuthClient) => fpAuthClient.clientId === DASHBOARD_BACKEND_CLIENT_ID
    );

    if (existingClient) return existingClient;

    const newFpAuthClient = await fpapi?.fpDashboardClient
      ?.access_management()
      .createFpAuthClient(name, {
        client_id: DASHBOARD_BACKEND_CLIENT_ID,
        name: DASHBOARD_BACKEND_CLIENT_NAME,
        type: "internal_app",
      });

    return newFpAuthClient;
  };

  const handleTenantChange = async (option: { id: string | number; [key: string]: any } | null) => {
    try {
      setLoading(true);
      setError(null);

      const selectedTenant = option?.value;
      if (selectedTenant) {
        const backendClient = await createBackendClient(`fpa_${selectedTenant}`);

        if (backendClient) {
          option && userInfo?.updateActiveTenant(selectedTenant);
        }
      }
    } catch (e) {
      window.debug.error(e);
      setError("Error switching tenant.");
      reportErrorToSentry(e);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loader variant="screenCover" />;
  }

  if (noTenantsAssigned) {
    return;
  }

  if (isFpConsoleActive) {
    return <PageTitle variant="sub" title="FP Console" customClass="mb-0" />;
  }

  if (!tenantOptions) {
    return;
  }

  if (userHasAccessToSingleTenantOnly) {
    return <PageTitle variant="sub" title={toTitleCase(activeTenant ?? "")} customClass="mb-0" />;
  }

  return (
    <div>
      <SearchAutocomplete
        variant="borderless"
        searchAttribute="label"
        value={tenantOptions[getOptionsIndex(tenantOptions, activeTenant)]}
        options={tenantOptions}
        onChange={handleTenantChange}
        shouldFocusAndSelectInputOnDropdown={true}
      />

      <ConditionalRenderer check={Boolean(error)}>
        <Toast variant="error" message={error ?? "Something went wrong"} duration={5000} />
      </ConditionalRenderer>
    </div>
  );
};

export default MenuHeader;
