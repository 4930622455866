import { ReactNode } from "react";

type SearchContainerProps = {
  onClick?: () => void;
  children: ReactNode;
};
function SearchContainer({ onClick, children }: Readonly<SearchContainerProps>) {
  return (
    <div className="flex-1" id="search-container" onClick={onClick}>
      {children}
    </div>
  );
}
export default SearchContainer;
