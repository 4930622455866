import { INTERNAL_USER_EMAIL_DOMAIN } from "./constants";

const composeValidators =
  (...validators: any[]) =>
  (value: any, allValues?: Record<any, any>) =>
    validators.reduce((error, validator) => error || validator(value, allValues), undefined);

const required = (value: any) => (value ? undefined : "This input is required");
const validateMinCharacters = (minLength: number) => (value: string | any[]) =>
  value?.length >= minLength ? undefined : `Please use atleast ${minLength} characters`;
const validateMaxCharacters = (maxLength: number) => (value: string | any[]) => {
  if (value) {
    return value.length <= maxLength
      ? undefined
      : `Please use a maximum of ${maxLength} characters`;
  }
};
export const validateDateIfPresent = (value: string | number | Date) => {
  if (value) {
    return new Date(value).toString() === "Invalid Date" ? "Invalid date" : undefined;
  }
};

export const isValidDate = (date: string | number | Date) => {
  if (date) {
    const d = new Date(date);
    if (Object.prototype.toString.call(d) === "[object Date]") {
      if (isNaN(d as unknown as number)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const isValidDateRangeIfPresent = (errMsg: string) => {
  return (dateRange?: [Date, Date] | null) => {
    if (!dateRange) {
      return undefined;
    }

    const [fromDate, toDate] = dateRange;

    if (isValidDate(fromDate) && isValidDate(toDate)) {
      return undefined;
    } else {
      return errMsg;
    }
  };
};

export const isToDateGreaterThanOrEqualToFromDate = (errMsg: string) => {
  return (dateRange?: [Date, Date] | null) => {
    if (!dateRange) {
      return undefined;
    }

    const [fromDate, toDate] = dateRange;

    if (isValidDate(fromDate) && isValidDate(toDate)) {
      if (fromDate <= toDate) {
        return undefined;
      } else {
        return errMsg;
      }
    } else {
      return "Invalid date";
    }
  };
};

export const isFromDateOrToDateNotInTheFuture = (errMsg: string) => {
  return (dateRange?: [Date, Date] | null) => {
    if (!dateRange) {
      return undefined;
    }

    const [fromDate, toDate] = dateRange;

    const todaysDate = new Date();
    todaysDate.setHours(23, 59, 59, 999);

    if (isValidDate(fromDate) && isValidDate(toDate)) {
      if (fromDate <= todaysDate && toDate <= todaysDate) {
        return undefined;
      } else {
        return errMsg;
      }
    } else {
      return "Invalid date";
    }
  };
};

const validateMinValue = (minValue: number) => (value: number) =>
  value > minValue ? undefined : `Minimum value should be more than ${minValue}`;

const validateMaxValue = (maxVal: number) => (value: number) =>
  value <= maxVal ? undefined : `Maximum value should be less than ${maxVal}`;

const validateBankAccNo = (customMsg: any) => (value: string) =>
  /^[0-9a-zA-Z]+$/.test(value) ? undefined : customMsg;

const validateArn = (customMsg: any) => (value: string) =>
  /^ARN-\d+$/.test(value) ? undefined : customMsg;

const validateRia = (customMsg: any) => (value: string) =>
  /^INA\d+$/.test(value) ? undefined : customMsg;

const validateEuin = (customMsg: any) => (value: string) =>
  /^E\d{6}$/.test(value) ? undefined : customMsg;

export function validateHttpURL(customMsg: string = "Invalid URL") {
  return (value: string) => {
    if (!value) {
      return;
    }

    try {
      const parsedUrl = new URL(value);
      const validURLProtocol = parsedUrl.protocol === "http:" || parsedUrl.protocol === "https:";
      return validURLProtocol ? undefined : customMsg;
    } catch {
      return customMsg;
    }
  };
}

export const validateName = (customMsg: any) => (value: string) =>
  /^[a-zA-Z]+(?: [a-zA-Z]+)*$/.test(value) ? undefined : customMsg;

const isMobileNumber = (value: string) => /^[6-9]\d{9}$/.test(value);

export const validateMobile = (customMsg: any) => (value: any) =>
  isMobileNumber(value) ? undefined : customMsg;

const isEmail = (value: string) =>
  /^[A-Za-z0-9._%+-]{1,64}@(?:[A-Za-z0-9-]{1,63}\.){1,125}[A-Za-z]{2,63}$/.test(value);

const isInternalEmail = (value: string) => {
  const expression = new RegExp(
    `^[A-Za-z0-9._%+-]{1,64}@(${INTERNAL_USER_EMAIL_DOMAIN}\\.){1,125}[A-Za-z]{2,63}$`
  );
  return expression.test(value);
};

export const validateEmail = (customMsg: any) => (value: any) =>
  isEmail(value) ? undefined : customMsg;

export const validateInternalEmail = (customMsg: any) => (value: any) =>
  isInternalEmail(value) ? undefined : customMsg;

export const requiredList = (value: any[] | null) =>
  value?.length ? undefined : "This input is required";

export const isDateRangeWithinOneYear = (errMsg: string) => {
  return (dateRange?: [Date, Date] | null) => {
    if (!dateRange) {
      return undefined;
    }

    const [fromDate, toDate] = dateRange;

    const msDiff = Number(toDate) - Number(fromDate);

    const daysDiff = msDiff / (24 * 60 * 60 * 1000);

    return daysDiff > 365 ? errMsg : undefined;
  };
};

export const validateLicenseCode = (value: string) => {
  return (
    validateArn("Enter valid license code")(value) && validateRia("Enter valid license code")(value)
  );
};

export const isValidInvestorPAN = (value: string) => {
  return /^[a-zA-Z]{3}[Pp][a-zA-Z][0-9]{4}[a-zA-Z]$/.test(value);
};

export const isValidCompanyPAN = (value: string) => {
  return /^[a-zA-Z]{3}[Cc][a-zA-Z][0-9]{4}[a-zA-Z]$/.test(value);
};

const isValidInvestmentAccountId = (value: string) => {
  return /^mfia_[a-zA-Z0-9]+$/.test(value);
};

export {
  composeValidators,
  required,
  validateMaxCharacters,
  validateMinCharacters,
  validateMinValue,
  validateMaxValue,
  validateBankAccNo,
  validateArn,
  validateRia,
  validateEuin,
  isValidInvestmentAccountId,
};
