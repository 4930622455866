type UsernameProps = {
  isSideBarCollapsed: boolean;
  currentUserName: string;
};
const Username = ({ isSideBarCollapsed, currentUserName }: UsernameProps) => {
  if (!isSideBarCollapsed) {
    return <span>{currentUserName}</span>;
  }
};
export default Username;
