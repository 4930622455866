import { ReactNode } from "react";

type HeaderContainerProps = {
  children: ReactNode;
};

function HeaderContainer({ children }: Readonly<HeaderContainerProps>) {
  return <div className="mb-4 flex items-center justify-between">{children}</div>;
}
export default HeaderContainer;
