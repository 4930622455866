import { SigninRedirectArgs, User, UserManager, UserManagerSettings } from "oidc-client-ts";
import { createContext, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { Loader } from "pixel";
import { useNavigate } from "react-router-dom";
import FullPageError from "../../components/display/FullPageError";
import { reportErrorToSentry, setSentryUser } from "../../global/sentry/sentry";
import { AUTH_REDIRECT_URI, BASE_URL } from "../../utils/constants";
import {
  getRealmSpecificAuthorityURL,
  hasVisitedConsoleSessionStorage,
  hasVisitedInvestSessionStorage,
  partnerSelectedFolioSessionStorage,
  partnerSelectedSchemeSessionStorage,
} from "../../utils/utils";
import { useConfiguration } from "../configuration/useConfiguration";
import { hasAuthParams } from "./utils";

export interface AuthProviderProps {
  children?: React.ReactNode;
  userManagerSettings: UserManagerSettings;
}

export interface AuthContextProps {
  userManager: UserManager | null;
  user: User | null;
  isAuthenticated: boolean;
  accessToken: User["access_token"] | null;
  logout(): Promise<void>;
  login(args?: SigninRedirectArgs): Promise<void>;
  isLoading: boolean;
  silentLogin(args?: SigninRedirectArgs): Promise<void>;
}

export type CreateAuthContextProps = AuthContextProps | undefined;

export const AuthContext = createContext<CreateAuthContextProps>(undefined);

const getExtraQueryParams = (extraQueryParams?: Record<string, string | number | boolean>) => {
  return extraQueryParams ? extraQueryParams.email : "";
};

const getUserForContextValue = (user: User | null) => {
  return user ? user?.access_token : null;
};
export const AuthProvider = (props: AuthProviderProps): JSX.Element => {
  const { children, userManagerSettings } = props;

  const navigate = useNavigate();
  const config = useConfiguration();
  const tenantConfig = config?.tenantConfig;

  const [userManager] = useState(() => {
    const config = userManagerSettings;
    return new UserManager(config);
  });

  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const isUserManagerInitialized = useRef(false);

  const onSigninCallback = useCallback((): void => {
    const redirectPath = sessionStorage.getItem("redirectPath");
    sessionStorage.removeItem("redirectPath");

    navigate(redirectPath ?? BASE_URL, { replace: true });
  }, [navigate]);

  const handleSignout = useCallback(async () => {
    await userManager.revokeTokens();
    setUser(null);

    sessionStorage.clear();
    localStorage.removeItem("realm");
    window.location.reload();
  }, [userManager]);

  useEffect(() => {
    const previousRedirectPath = sessionStorage.getItem("redirectPath");
    if (!userManager || isUserManagerInitialized.current) {
      return;
    }

    isUserManagerInitialized.current = true;
    void (async (): Promise<void> => {
      try {
        setIsLoading(true);
        // check if returning back from authority server
        if (hasAuthParams()) {
          sessionStorage.removeItem("isSilentLoginAttempted");
          await userManager.signinCallback();
          onSigninCallback();
        }
      } catch (error) {
        //DO NOT LOG TO SENTRY, this catch is used to check if login required or not
        window.debug.error(error);
        sessionStorage.setItem("isSilentLoginAttempted", "true");
        navigate(previousRedirectPath ?? BASE_URL, { replace: true });
      } finally {
        setIsLoading(false);
      }
    })();
  }, [userManager, onSigninCallback, navigate]);

  // register UserManger events
  useEffect(() => {
    if (!userManager) {
      return undefined;
    }

    const handleUserUnloaded = () => {
      setUser(null);
      setIsLoading(true);
      handleSignout();
    };

    const handleSilentRenewError = (error: Error) => {
      reportErrorToSentry(error);
      handleSignout();
      setIsLoading(false);
    };

    const handleAccessTokenExpired = () => {
      userManager.removeUser();
      setIsLoading(false);
    };

    const handleUserLoaded = async (user: any) => {
      setSentryUser({ email: user?.profile?.email });

      setUser(user);
    };

    userManager.events.addUserUnloaded(handleUserUnloaded);
    userManager.events.addAccessTokenExpired(handleAccessTokenExpired);
    userManager.events.addSilentRenewError(handleSilentRenewError);
    userManager.events.addUserLoaded(handleUserLoaded);

    return () => {
      userManager.events.removeUserUnloaded(handleUserUnloaded);
      userManager.events.removeAccessTokenExpired(handleAccessTokenExpired);
      userManager.events.removeSilentRenewError(handleSilentRenewError);
      userManager.events.removeUserLoaded(handleUserLoaded);
    };
  }, [userManager, handleSignout]);

  const authContextValue = useMemo(() => {
    const login = async (args?: SigninRedirectArgs) => {
      const { extraQueryParams } = args ?? {};
      const URLWithQueryParams = window.location.pathname + window.location.search;

      sessionStorage.setItem("redirectPath", URLWithQueryParams);
      setIsLoading(true);

      if (
        extraQueryParams?.tenant &&
        typeof extraQueryParams?.tenant === "string" &&
        tenantConfig
      ) {
        localStorage.setItem("realm", extraQueryParams?.tenant);

        const tenantAuthorityURL = getRealmSpecificAuthorityURL(
          tenantConfig?.realmUrl,
          extraQueryParams?.tenant
        );

        const newUserManagerSettings = {
          authority: tenantAuthorityURL,
          client_id: tenantConfig?.["client_id"],
          redirect_uri: AUTH_REDIRECT_URI,
          automaticSilentRenew: true,
        };

        const newUserManager = new UserManager(newUserManagerSettings);
        newUserManager
          .signinRedirect({
            extraQueryParams: {
              login_hint: getExtraQueryParams(extraQueryParams),
            },
          })
          .catch((e) => {
            reportErrorToSentry(e);
            setError(e?.error);
            setIsLoading(false);
          });
      } else {
        localStorage.removeItem("realm");
        if (tenantConfig) {
          const newUserManagerSettings = {
            authority: tenantConfig?.["realmUrl"],
            client_id: tenantConfig?.["client_id"],
            redirect_uri: AUTH_REDIRECT_URI,
            automaticSilentRenew: true,
          };
          const newUserManager = new UserManager(newUserManagerSettings);
          await newUserManager
            .signinRedirect({
              extraQueryParams: {
                login_hint: getExtraQueryParams(extraQueryParams),
              },
            })
            .catch((e) => {
              reportErrorToSentry(e);
              setError(e?.error);
              setIsLoading(false);
            });
        }
      }

      setIsLoading(false);
    };

    const silentLogin = async () => {
      const URLWithQueryParams = window.location.pathname + window.location.search;

      sessionStorage.setItem("redirectPath", URLWithQueryParams);
      setIsLoading(true);

      await userManager.signinRedirect({
        extraQueryParams: {
          prompt: "none",
        },
      });

      setIsLoading(false);
    };

    const logout = async () => {
      try {
        setIsLoading(true);
        const current_user = await userManager.getUser();
        const idToken = current_user?.id_token;

        localStorage.removeItem("realm");
        hasVisitedConsoleSessionStorage.remove();
        hasVisitedInvestSessionStorage.remove();
        partnerSelectedFolioSessionStorage.remove();
        partnerSelectedSchemeSessionStorage.remove();
        await userManager.revokeTokens();
        await userManager.signoutRedirect({
          id_token_hint: idToken,
          redirectMethod: "replace",
          post_logout_redirect_uri: window.location.origin + BASE_URL,
        });
        setUser(null);
      } catch (err) {
        window.debug.warn("No logged in user");
      } finally {
        setIsLoading(false);
      }
    };

    return {
      userManager,
      user,
      isAuthenticated: !!user,
      accessToken: getUserForContextValue(user),
      logout,
      login,
      isLoading,
      silentLogin,
    };
  }, [userManager, user, isLoading, tenantConfig]);

  if (error) {
    return (
      <FullPageError
        errorTitle={error}
        errorDescription="Retry or check back later"
        actionText="Retry"
        action={() => {
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = window.location.origin + BASE_URL;
        }}
      />
    );
  }
  return (
    <AuthContext.Provider value={authContextValue}>
      {isLoading && <Loader variant="fullpage" />}
      {children}
    </AuthContext.Provider>
  );
};
