export enum Role {
  ANALYST = "analyst",
  CUSTOMER_SUCCESS = "cyb-customer-success",
  OPERATIONS = "cyb-operations",
  TENANT_WORKFORCE = "tnt-workforce",
  CYBRILLA_ADMIN = "cyb-admin",
  TENANT_OPERATIONS = "tnt-operations",
  TENANT_ROOT = "tnt-root",
  TENANT_PARTNER = "tnt-partner",
}

export type Roles = Role[];

export type UserInfoResponse = {
  sub: string;
  tenants: string[];
  email_verified: boolean;
  roles: Roles;
  given_name: string;
  family_name: string;
  email: string;
  type: "internal" | "workforce";
  fp_identifier?: string;
};

export const RoleLabelMap: Record<Role, string> = {
  analyst: "analyst",
  "cyb-customer-success": "customer success",
  "cyb-admin": "cybrilla admin",
  "cyb-operations": "operations",
  "tnt-workforce": "workforce",
  "tnt-operations": "tenant operations",
  "tnt-root": "tenant root",
  "tnt-partner": "tenant partner",
};

export const TenantPartnerRoles: Role[] = [Role.TENANT_PARTNER];

export const TenantRoles: Role[] = [Role.ANALYST, Role.TENANT_WORKFORCE, Role.TENANT_OPERATIONS];

export const TenantRootRoles: Role[] = [Role.TENANT_ROOT];

export const InternalRoles: Role[] = [
  Role.ANALYST,
  Role.CUSTOMER_SUCCESS,
  Role.OPERATIONS,
  Role.CYBRILLA_ADMIN,
];
