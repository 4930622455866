import { Loader } from "pixel";
import { Suspense } from "react";
import AppWithRoutes from "./AppWithRoutes";
import Masterlayout from "./Layout/Masterlayout";
import UIStateProvider from "./packages/providers/UIStateProvider";

const App = () => {
  return (
    <UIStateProvider>
      <Masterlayout>
        <Suspense fallback={<Loader variant="fullpage" />}>
          <AppWithRoutes />
        </Suspense>
      </Masterlayout>
    </UIStateProvider>
  );
};
export default App;
