//Pixel imports
import React from "react";
import { Button } from "pixel";
import { t } from "@lingui/macro";
import { tenantEnv } from "../../utils/utils";
import { BASE_URL, NOT_FOUND_IMAGE_URL } from "../../utils/constants";

/**
 * React component to show not found page
 *
 * @method NotFound -  Render not found page
 *
 */
export default function NotFoundPage() {
  const handleGoToHome = () => {
    window.location.href = window.location.origin + BASE_URL;
  };

  return (
    <div className="item-center flex justify-center">
      <img
        src={NOT_FOUND_IMAGE_URL}
        className="absolute top-9 w-full"
        style={{ top: "48px" }}
        alt="Not found"
      />
      <div className="relative z-10 pt-40 pl-10">
        <h2 className="text-neutral-dark-0 text-size-32 dark:text-neutral-dark-110 mb-2">
          {t({
            message: "Looks like you got lost",
            context: "NotFoundPage heading",
          })}
        </h2>
        <p className="text-size-14 text-neutral-60 mb-4 leading-normal">
          {t({
            message: `${
              tenantEnv.isStagingOrSandbox
                ? "You can go back to home and search from the available options. "
                : ""
            }In case you need any help, please contact us.`,
            context: "NotFoundPage description",
          })}
        </p>

        {tenantEnv.isStagingOrSandbox && (
          <div className="mb-2">
            <Button variant="text" onClick={handleGoToHome}>
              {t({
                message: "Back to dashboard",
                context: "NotFoundPage CTA",
              })}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
