import { useState } from "react";
import { Button, Chip, ChipContainer, Modal } from "pixel";
import { formatDateForTable, isEmptyObject } from "../../../utils/utils";
import { filterNameDisplayLabelMapping } from "./constants";
import { EventFilterPillsProps, Filters } from "./types";

function EventFilterPills({
  selectedFilters,
  handleUpdateBatchFilters,
}: Readonly<EventFilterPillsProps>) {
  const [pillContainerModal, setPillContainerModal] = useState(false);

  const handleRemoveFilter = (batchFilterName: keyof Filters) => {
    const newFilters = { ...selectedFilters };
    delete newFilters[batchFilterName];
    handleUpdateBatchFilters(newFilters);
  };

  const renderFormattedDateRange = (dateRangeStr: string) => {
    const dateRangeSplit = dateRangeStr.split("--")?.map((date: string) => new Date(date));

    return `${formatDateForTable(
      dateRangeSplit[0],
      "dd MMM yyyy HH:mm:ss:ms"
    )} - ${formatDateForTable(dateRangeSplit[1], "dd MMM yyyy HH:mm:ss:ms")}`;
  };

  const renderPills = () => {
    return (
      selectedFilters &&
      Object.entries(selectedFilters).map(([batchFilterName, batchFilterValue]) => {
        const chipTitle = batchFilterName in filterNameDisplayLabelMapping;

        return chipTitle && batchFilterValue ? (
          <Chip
            key={batchFilterName}
            title={filterNameDisplayLabelMapping?.[batchFilterName as keyof Filters]}
            onClick={() => handleRemoveFilter(batchFilterName as keyof Filters)}
            customClass="mr-2"
          >
            {batchFilterName === "date_range"
              ? `${renderFormattedDateRange(batchFilterValue)}`
              : batchFilterValue}
          </Chip>
        ) : null;
      })
    );
  };

  return (
    <>
      <Modal
        variant="fullPage"
        title="Selected filters"
        isOpen={pillContainerModal}
        toggleModal={() => setPillContainerModal(!pillContainerModal)}
        fullWidth
        contentHeightMax={false}
      >
        <div className="overflow-auto px-2" style={{ maxHeight: "280px" }}>
          {renderPills()}
        </div>

        {!isEmptyObject(selectedFilters) && (
          <div className="px-2">
            <Button
              variant="primary"
              type="button"
              size="sm"
              fullwidth={true}
              customClass="customClassname"
              onClick={() => {
                handleUpdateBatchFilters({});
                setPillContainerModal(false);
              }}
            >
              Clear all
            </Button>
          </div>
        )}
      </Modal>

      <ChipContainer
        onClick={() => {
          setPillContainerModal(true);
        }}
        maxWidth="90%"
      >
        {renderPills()}
      </ChipContainer>
    </>
  );
}
export default EventFilterPills;
