import { useEffect, useState } from "react";
import { useConfiguration } from "../configuration/useConfiguration";
import { AuthProvider } from "./AuthProvider";
import { Loader } from "pixel";
import { AUTH_REDIRECT_URI } from "../../utils/constants";
import { useUserInfo } from "../userinfo/useUserInfo";
import { getRealmSpecificAuthorityURL } from "../../utils/utils";
import { useAuth } from "./useAuth";

interface AuthProviderWrapperProps {
  children: React.ReactNode;
}

interface UserManagerSettings {
  authority: string;
  client_id: string;
  client_secret: string;
}

const AuthProviderWrapper = ({ children }: AuthProviderWrapperProps) => {
  const [userManagerSettings, setUserManagerSettings] =
    useState<UserManagerSettings | null>(null);
  const realm = localStorage.getItem("realm");

  const config = useConfiguration();
  const tenantConfig = config?.tenantConfig;

  useEffect(() => {
    
    if (realm && tenantConfig) {

      const tenantAuthorityURL = getRealmSpecificAuthorityURL(tenantConfig?.realmUrl, realm!);
      setUserManagerSettings({
        authority: tenantAuthorityURL,
        client_id: tenantConfig?.["client_id"],
        client_secret: tenantConfig?.["client_secret"],
      });
    } else {

      if (tenantConfig) {
        setUserManagerSettings({
          authority: tenantConfig?.["realmUrl"],
          client_id: tenantConfig?.["client_id"],
          client_secret: tenantConfig?.["client_secret"],
        });
      }
    }
  }, [tenantConfig]);

  return (
    <>
      {userManagerSettings ? (
        <AuthProvider
          userManagerSettings={{
            ...userManagerSettings,
            redirect_uri: AUTH_REDIRECT_URI,
            automaticSilentRenew: true,
          }}
        >
          <>{children}</>
        </AuthProvider>
      ) : (
        <Loader variant="fullpage" />
      )}
    </>
  );
};
export default AuthProviderWrapper;
