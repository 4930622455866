import { createContext, PropsWithChildren, useState } from "react";

type UIStateContextType = {
  isMobileMenuCollapsed: boolean;
  isSideBarCollapsed: boolean;
  handleToggleMobileMenu: (isMobileMenuOpen: boolean) => void;
  handleToggleSidebar: (isSideBarOpen: boolean) => void;
};

export const UIStateContext = createContext<UIStateContextType | null>(null);

const UIStateProvider = ({ children }: PropsWithChildren) => {
  const [isMobileMenuCollapsed, setIsMobileMenuCollapsed] = useState(() => {
    const savedCollapseState = localStorage.getItem("isMobileMenuCollapsed");
    return savedCollapseState ? JSON.parse(savedCollapseState) : true;
  });
  const [isSideBarCollapsed, setIsSideBarCollapsed] = useState(() => {
    const savedCollapseState = localStorage.getItem("isSideBarCollapsed");
    return savedCollapseState ? JSON.parse(savedCollapseState) : false;
  });

  const handleToggleMobileMenu = (isMobileMenuOpen: boolean) => {
    setIsMobileMenuCollapsed(isMobileMenuOpen);
    localStorage.setItem("isMobileMenuCollapsed", JSON.stringify(isMobileMenuOpen));
  };

  const handleToggleSidebar = (isSideBarOpen: boolean) => {
    setIsSideBarCollapsed(isSideBarOpen);
    localStorage.setItem("isSideBarCollapsed", JSON.stringify(isSideBarOpen));
  };

  return (
    <UIStateContext.Provider
      value={{
        isMobileMenuCollapsed,
        isSideBarCollapsed,
        handleToggleSidebar,
        handleToggleMobileMenu,
      }}
    >
      {children}
    </UIStateContext.Provider>
  );
};

export default UIStateProvider;
