//Pixel imports
import { FPLogo, LandingImage, Tabs } from "pixel";
import RootUser from "./Forms/RootUser";
import WIAMUser from "./Forms/WIAMUser";
import { RootUserFormData, WIAMUserFormData } from "./Forms/types";

interface LandingPageProps {
  onLandingPageSubmit: (data: WIAMUserFormData | RootUserFormData) => void;
}

export default function LandingPage({ onLandingPageSubmit }: LandingPageProps) {
  const renderLandingPageForms = () => (
    <div className="wrapper bg-gradient-50 dark:bg-neutral-dark-0">
      <div className="container relative mx-auto">
        <header className="bg-neutral-0 dark:bg-neutral-85 shadow-4xl fixed left-0 right-0 z-10 flex justify-between px-6 py-4 dark:shadow-transparent md:absolute md:bg-transparent md:pt-5 md:shadow-none md:dark:bg-transparent">
          <div>
            <FPLogo />
          </div>
          <nav>
            <ul className="flex items-center">
              <li className="pl-12">
                <a
                  className="font-roboto text-neutral-110 dark:text-neutral-dark-80"
                  href="https://docs.fintechprimitives.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Product guide
                </a>
              </li>
            </ul>
          </nav>
        </header>
        <div className="flex flex-col-reverse md:flex-row">
          <div className="col-left flex flex-col items-center justify-center px-6 md:min-h-screen md:w-1/2 md:basis-1/2 lg:pr-24 lg:pt-24">
            <div className="bg-primary-10 dark:bg-neutral-dark-0 mb-4 px-4 py-4">
              <h4 className="font-roboto text-neutral-110 dark:text-neutral-dark-110 mb-4 text-center text-xl leading-snug md:text-left lg:text-5xl">
                Selling mutual funds made simple with FP
              </h4>
              <p className="font-roboto text-size-18 max-w-64 text-neutral-40 mb-2 max-w-full text-center md:mb-8 md:text-left ">
                Fintech Primitives(FP) offers APIs and tools to launch your own mutual funds
                platform.
              </p>
              <LandingImage customClass="w-full lg:w-auto hidden md:block " />
            </div>
          </div>
          <div className="col-right flex flex-col justify-center px-6 pt-24 md:min-h-screen md:w-1/2 md:basis-1/2 lg:pl-24">
            <div>
              <h5 className="font-roboto text-neutral-110 dark:text-neutral-dark-110 mb-6 text-2xl leading-normal">
                Welcome
              </h5>
              <Tabs
                selected={1}
                group={[
                  {
                    title: "Root user",
                    content: (
                      <>
                        <p className="text-neutral-60 dark:text-neutral-40 mb-4 font-sans">
                          Root user is the account owner that performs tasks that require
                          unrestrictive access of the account.
                        </p>
                        <RootUser
                          onRootUserSubmit={(data: RootUserFormData) => onLandingPageSubmit(data)}
                        />
                      </>
                    ),
                  },
                  {
                    title: "Workforce user",
                    content: (
                      <>
                        <p className="text-neutral-60 dark:text-neutral-40 mb-4 font-sans">
                          Workforce user can access the account where daily tasks are performed.
                        </p>
                        <WIAMUser
                          onWIAMUserSubmit={(data: WIAMUserFormData) => onLandingPageSubmit(data)}
                        />
                      </>
                    ),
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return renderLandingPageForms();
}
