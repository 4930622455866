import { useErrorBoundary } from "react-error-boundary";
import { Button, Layout } from "pixel";
const NotFoundSvg = "/images/bg-notfind.svg"; //TODO find where this is ?

export default function ErrorBoundaryFallback() {
  const { resetBoundary } = useErrorBoundary();

  return (
    <Layout>
      <img
        src={NotFoundSvg}
        className="absolute left-0 top-0 w-full"
        alt="Not found"
      />

      <div className="relative z-10 pt-40">
        <h2 className="text-neutral-dark-0 text-size-32 dark:text-neutral-dark-110 mb-2">
          Something went wrong!
        </h2>

        <p className="text-size-14 text-neutral-60 mb-4 leading-normal">
          Try refreshing the page or try again later.
        </p>

        <Button customClass="mb-2" variant="text" onClick={resetBoundary}>
          Try again
        </Button>
      </div>
    </Layout>
  );
}
