// Core
import { useEffect, useMemo, useState } from "react";
import { Field, Form } from "react-final-form";

// Pixel
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Daterangepicker,
  FixedWrapper,
  Message,
  SearchAutocomplete,
  TextInput,
  WidgetList,
  WidgetListItem,
} from "pixel";

// Helper
import {
  anyCharacterExcluding,
  getOptionsIndex,
  scrollToErrorField,
  showFieldError,
} from "../../../utils/utils";
import { DateRange, EventFiltersFormData, EventFiltersProps, Filters } from "./types";
import {
  composeValidators,
  isFromDateOrToDateNotInTheFuture,
  isToDateGreaterThanOrEqualToFromDate,
  isValidDateRangeIfPresent,
  validateMaxCharacters,
} from "../../../utils/validators";

export default function EventFilters({
  eventTypeOptions,
  fetchEventTypeOptions,
  selectedFilters,
  handleSubmission,
}: Readonly<EventFiltersProps>) {
  const [expanded, setExpanded] = useState<string | boolean>(false);

  useEffect(() => {
    if (!eventTypeOptions.length) {
      fetchEventTypeOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventTypeOptions]);

  const formInitialValues = useMemo(() => {
    const initialValues: EventFiltersFormData = {
      type: eventTypeOptions[getOptionsIndex(eventTypeOptions, selectedFilters?.type)],
      fp_object_id: selectedFilters?.fp_object_id,
      date_range:
        (selectedFilters?.date_range
          ?.split("--")
          .map((timeStampStr) => new Date(timeStampStr)) as DateRange) ?? null,
    };

    return initialValues;
  }, [selectedFilters, eventTypeOptions]);

  const handleSubmit = (data: EventFiltersFormData) => {
    const filters: Filters = {
      type: data.type?.value,
      fp_object_id: data?.fp_object_id,
      date_range: data.date_range?.map((date) => date.toISOString()).join("--"),
    };

    handleSubmission(filters);
  };

  const handleAccordionChange = (panel: string) => {
    return (e: React.MouseEvent<HTMLDivElement, MouseEvent>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  };

  return (
    <Form onSubmit={handleSubmit} initialValues={formInitialValues}>
      {(formProps) => {
        return (
          <form onSubmit={formProps.handleSubmit} className="flex h-full flex-col justify-between">
            <div>
              <div id="fp_object_id">
                <Accordion
                  bg
                  expanded={expanded === "fp_object_id"}
                  onChange={handleAccordionChange("fp_object_id")}
                >
                  <AccordionSummary bg>FP object id</AccordionSummary>

                  <AccordionDetails bg>
                    <WidgetList>
                      <WidgetListItem bg customClass="flex flex-col space-y-4 justify-between">
                        <Field
                          name="fp_object_id"
                          validate={composeValidators(validateMaxCharacters(100))}
                        >
                          {(props) => (
                            <>
                              <TextInput
                                id={props.input.name}
                                autoComplete="off"
                                name={props.input.name}
                                value={props.input.value}
                                onChange={props.input.onChange}
                                onBlur={() => {
                                  props.input.onChange(props.input.value.trim());
                                }}
                                onInput={(event) => {
                                  event.target.value = event.target.value.replace(
                                    anyCharacterExcluding("a-zA-Z_0-9"),
                                    ""
                                  );
                                }}
                                placeholder={"Enter FP object id"}
                                maxLength={100}
                                status={showFieldError(props)}
                              />

                              {showFieldError(props) && (
                                <Message type="error" variant="field" title={props.meta.error} />
                              )}
                            </>
                          )}
                        </Field>
                      </WidgetListItem>
                    </WidgetList>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div id="type">
                <Accordion
                  bg
                  expanded={expanded === "type"}
                  onChange={handleAccordionChange("type")}
                >
                  <AccordionSummary bg>Event type</AccordionSummary>

                  <AccordionDetails bg>
                    <WidgetList>
                      <WidgetListItem bg customClass="flex flex-col space-y-4 justify-between">
                        <Field name="type">
                          {(props) => (
                            <>
                              <SearchAutocomplete
                                value={props.input.value}
                                onChange={(value: any) => props.input.onChange(value)}
                                options={eventTypeOptions}
                                searchAttribute="label"
                                variant="autocomplete"
                                disabled={false}
                              />

                              {showFieldError(props) && (
                                <Message type="error" variant="field" title={props.meta.error} />
                              )}
                            </>
                          )}
                        </Field>
                      </WidgetListItem>
                    </WidgetList>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div id="date_range">
                <Accordion
                  bg
                  expanded={expanded === "date_range"}
                  onChange={handleAccordionChange("date_range")}
                >
                  <AccordionSummary bg>Date range</AccordionSummary>

                  <AccordionDetails bg>
                    <WidgetList>
                      <WidgetListItem bg customClass="flex flex-col space-y-4 justify-between">
                        <Field
                          name="date_range"
                          validate={composeValidators(
                            isValidDateRangeIfPresent("Invalid date"),
                            isToDateGreaterThanOrEqualToFromDate(
                              "To date cannot be lesser than from date"
                            ),
                            isFromDateOrToDateNotInTheFuture(
                              "From or to date cannot be in the future"
                            )
                          )}
                        >
                          {(props) => (
                            <>
                              <Daterangepicker
                                value={props.input.value || null}
                                onChange={(dateRange: DateRange | null) =>
                                  props.input.onChange(dateRange)
                                }
                                limitEndYear={new Date().getFullYear()}
                                shouldDisableDate={(date: Date) => {
                                  const todaysDate = new Date();
                                  todaysDate.setHours(23, 59, 59, 999);
                                  return date > todaysDate; // disable future
                                }}
                                format="dd MMM yyyy hh:mm:ss aa"
                                showMeridian
                                character=" - "
                                placement="bottomEnd"
                              />
                              {showFieldError(props) && (
                                <Message type="error" variant="field" title={props.meta.error} />
                              )}
                            </>
                          )}
                        </Field>
                      </WidgetListItem>
                    </WidgetList>
                  </AccordionDetails>
                </Accordion>
              </div>
            </div>

            <FixedWrapper variant="contained">
              <Button
                type="submit"
                size="sm"
                fullwidth={true}
                onClick={() => {
                  const errors = Object.keys(formProps.errors ?? {});
                  if (errors.length) {
                    scrollToErrorField(errors[0]);
                  }
                }}
              >
                Apply
              </Button>
            </FixedWrapper>
          </form>
        );
      }}
    </Form>
  );
}
