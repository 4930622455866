import { useContext } from "react";
import { UIStateContext } from "../providers/UIStateProvider";

const useUIState = () => {
  const uiStateContext = useContext(UIStateContext);

  if (!uiStateContext) {
    throw new Error("useUIState must be used within a UIStateProvider");
  }

  return uiStateContext;
};
export default useUIState;
