//Core imports
import { useMemo } from "react";
import { Field, Form, FormRenderProps } from "react-final-form";

//Pixel imports
import { Button, Label, Message, PanelBlock, PanelCol, PanelRow, TextInput } from "pixel";

//Helpers imports
import { t } from "@lingui/macro";
import { composeValidators, required, validateEmail } from "../../../../utils/validators";
import { RootUserFormData } from "./types";
import { showFieldError } from "../../../../utils/utils";

interface RootUserProps {
  onRootUserSubmit: (data: RootUserFormData) => void;
}

export default function RootUser({ onRootUserSubmit }: RootUserProps) {
  const formInitialValues = useMemo(() => {
    const initialValues = {
      email: "",
    };

    return initialValues;
  }, []);

  function renderForm(formProps: FormRenderProps<RootUserFormData>) {
    return (
      <form onSubmit={formProps.handleSubmit}>
        <PanelBlock>
          <PanelRow>
            <PanelCol customClass="mb-8" span={12}>
              <Label
                message={t({
                  message: "Email",
                  context: "Label",
                })}
              />

              <Field
                name="email"
                validate={composeValidators(required, validateEmail("Enter valid email"))}
                validateFields={[]}
                subscription={{
                  touched: true,
                  error: true,
                  value: true,
                }}
              >
                {(props) => (
                  <>
                    <TextInput
                      name={props.input.name}
                      value={props.input.value}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        props.input.onChange(e.target.value)
                      }
                      id={props.input.name}
                      autoFocus
                    />
                    {showFieldError(props) && (
                      <Message type="error" variant="field" title={props.meta.error} />
                    )}
                  </>
                )}
              </Field>
            </PanelCol>

            <PanelCol customClass="mb-8" span={12}>
              <Button type="submit" customClass="mr-4" fullwidth={true}>
                Next
              </Button>
            </PanelCol>
          </PanelRow>
        </PanelBlock>
      </form>
    );
  }

  const renderWIAMUserForm = () => (
    <Form
      onSubmit={onRootUserSubmit}
      initialValues={formInitialValues}
      validate={() => {
        const errors = {};
        return errors;
      }}
      subscription={{
        submitting: true,
        values: true,
        pristine: true,
        errors: true,
        touched: true,
      }}
    >
      {renderForm}
    </Form>
  );

  return renderWIAMUserForm();
}
