import { useContext } from "react";
import { FpApiContext } from "./FpApiProvider";

export const useFpapi = () => {
  const fpApiContext = useContext(FpApiContext);

  if (!fpApiContext) {
    throw new Error("useFpapi must be used within a FpApiProvider");
  }

  return fpApiContext;
};
