import { FileOperationsFetchAllRequestParams } from "@fintechprimitives/fpapi";
import { useFpapi } from "../../../context/fpapi/useFpapi";
import { reportErrorToSentry } from "../../../global/sentry/sentry";
import { useState } from "react";
import FullPageError, { FullPageErrorProps } from "../../display/FullPageError";
import { Button, Loader, Toast } from "pixel";
import ThemedDataTable from "../../display/ThemedDataTable";
import HeaderContainer from "../../display/ListingPage/HeaderContainer";
import PageHeading from "../../display/ListingPage/PageHeading";
import { useLocation, useNavigate } from "react-router-dom";
import { ConditionalRenderer } from "../../../utils/helpers";
import { DownloadToastMessage } from "./types";
import { ColDef, ICellRendererParams } from "ag-grid-community";
import { formatDateForTable, getEitherValue } from "../../../utils/utils";

interface FileOperationsListingProps {
  showBackButton?: boolean;
}

const getDateValue = (dateString: string) => {
  const dateValue = new Date(dateString);
  return `${dateValue && !isNaN(dateValue.getTime()) ? formatDateForTable(dateValue) : "-"}`;
};

const colDefs = (downloadFileHandler: (param: string) => void): ColDef[] => {
  const getDownloadCTA = (fileID: string, fileName?: string) => (
    <Button
      disabled={!fileID}
      variant="text"
      onClick={() => downloadFileHandler(fileID)}
      customClass="text-size-14"
    >
      {fileName ?? "Download"}
    </Button>
  );
  return [
    {
      headerName: "ID",
      field: "id",
      width: 180,
      sortable: false,
    },
    {
      headerName: "Type",
      field: "type",
      width: 100,
      sortable: false,
    },
    {
      headerName: "Status",
      field: "status",
      width: 100,
      sortable: false,
    },
    {
      headerName: "File",
      field: "file",
      width: 400,
      sortable: false,
      cellRenderer: function (params: ICellRendererParams) {
        return getDownloadCTA(params?.value, params?.data?.file_name || params?.value);
      },
    },
    {
      headerName: "Processed records",
      field: "processed",
      width: 100,
      sortable: false,
      cellRenderer: function (params: ICellRendererParams) {
        return getEitherValue(params?.value?.toString(), "-");
      },
    },
    {
      headerName: "Success records",
      field: "succeeded",
      width: 100,
      sortable: false,
      cellRenderer: function (params: ICellRendererParams) {
        return getEitherValue(params?.value?.toString(), "-");
      },
    },
    {
      headerName: "Failed records",
      field: "failed",
      width: 100,
      sortable: false,
      cellRenderer: function (params: ICellRendererParams) {
        return getEitherValue(params?.value?.toString(), "-");
      },
    },
    {
      headerName: "Created at",
      field: "created_at",
      width: 180,
      sortable: false,
      cellRenderer: function (params: ICellRendererParams) {
        return getDateValue(params?.value);
      },
    },
    {
      headerName: "Updated at",
      field: "updated_at",
      width: 180,
      sortable: false,
      cellRenderer: function (params: ICellRendererParams) {
        return getDateValue(params?.value);
      },
    },
  ];
};

export default function FileOperationsListing({
  showBackButton = false,
}: FileOperationsListingProps) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<FullPageErrorProps | null>(null);
  const [downloadToastMessage, setDownloadToastMessage] = useState<DownloadToastMessage | null>(
    null
  );

  const { fpapi } = useFpapi();
  const { state } = useLocation();
  const navigate = useNavigate();

  const PAGE_SIZE_LIMIT = 20;
  const PAGE_TITLE = "File operations";

  const fetchData = async (page: number) => {
    const params: FileOperationsFetchAllRequestParams = {
      page: page - 1,
      size: PAGE_SIZE_LIMIT,
    };

    try {
      const fileOperationsList = await fpapi?.fpDashboardClient?.file_operations().fetchAll(params);

      return fileOperationsList?.data;
    } catch (e) {
      reportErrorToSentry(e);
      if (page === 1) {
        return [];
      }
      setError({
        errorTitle: "Unable to fetch uploaded feeds",
        errorDescription: "Check back later",
      });
    }
  };

  const downloadHandler = async (fileId: string) => {
    try {
      setDownloadToastMessage(null);
      setLoading(true);

      const downloadResponse = await fpapi?.fpDashboardClient?.files().fetch(fileId);

      const link = document.createElement("a");
      link.href = downloadResponse?.url ?? "";
      link.target = "_blank";
      link.download = fileId;
      link.click();
      setDownloadToastMessage({ message: "File downloaded successfully", variant: "success" });
      link.remove();
    } catch (error) {
      reportErrorToSentry(error);
      setDownloadToastMessage({
        variant: "error",
        message: "Error downloading the file",
      });
    } finally {
      setLoading(false);
    }
  };

  const renderTable = () => {
    if (error) {
      return (
        <FullPageError
          errorTitle={error?.errorTitle}
          errorDescription={error?.errorDescription}
          actionText={error?.actionText}
          action={error?.action}
        />
      );
    }
    return (
      <ThemedDataTable
        columnDefs={colDefs(downloadHandler)}
        height={"70vh"}
        fetchData={fetchData}
        cacheBlockSize={PAGE_SIZE_LIMIT}
        rowModelType="infinite"
        enableCellTextSelection={true}
      />
    );
  };

  const renderPage = () => {
    return (
      <>
        <HeaderContainer>
          {showBackButton ? (
            <PageHeading
              title={PAGE_TITLE}
              onClick={() => (state ? navigate(-1) : navigate("/operations/order-operations"))}
              icon={"Back"}
            />
          ) : (
            <PageHeading title={PAGE_TITLE} />
          )}
        </HeaderContainer>

        <div className="relative">{renderTable()}</div>
        <ConditionalRenderer check={Boolean(downloadToastMessage)}>
          <Toast
            variant={downloadToastMessage?.variant}
            message={downloadToastMessage?.message || ""}
            duration={2000}
          />
        </ConditionalRenderer>
      </>
    );
  };

  return loading ? <Loader variant="fullpage" /> : renderPage();
}
