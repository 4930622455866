import { Avatar } from "pixel";
import { useLocation } from "react-router-dom";
import { useUserInfo } from "../packages/context/userinfo/useUserInfo";
import useUIState from "../packages/hooks/useUIState";
import { BASE_URL } from "../packages/utils/constants";
import { matchCurrentPath } from "./utils";

const getImageClass = (isSideBarCollapsed: boolean) => {
  return isSideBarCollapsed ? "m-0" : "mr-4";
};

const MenuHeaderLogo = () => {
  const location = useLocation();
  const userInfo = useUserInfo();
  const { isSideBarCollapsed } = useUIState();
  const fpLogoSrc = BASE_URL + "/fplogo.svg";
  const activeTenant = userInfo?.data.activeTenant;
  const isFpConsoleActive = matchCurrentPath(location, "/fp-console");

  if (isFpConsoleActive) {
    return <></>;
  }

  if (fpLogoSrc) {
    return (
      <img src={fpLogoSrc} className={getImageClass(isSideBarCollapsed)} width={32} alt="Console" />
    );
  }

  return (
    <Avatar customClass="w-9 h-9 text-size-14 mr-4">{activeTenant?.charAt(0).toUpperCase()}</Avatar>
  );
};
export default MenuHeaderLogo;
