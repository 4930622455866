import { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

import ErrorBoundaryFallback from "../../components/display/ErrorBoundaryFallback";
import AuthProviderWrapper from "../../context/auth/AuthProviderWrapper";
import ConfigurationProvider from "../../context/configuration/ConfigurationContext";
import FpApiProviderWrapper from "../../context/fpapi/FpApiProviderWrapper";
import { initializeDebugger } from "../debugger";
import { dynamicActivate } from "../../utils/i18n";
import UserInfoProvider from "../../context/userinfo/UserInfoContext";
import { initializeErrorMonitoring, reportErrorToSentry } from "../sentry/sentry";
import { ThemeProvider } from "../../providers/ThemeProvider";

type FpAppsInitProps = {
  children?: ReactNode;
};

// Activate the locale for I18nProvider
dynamicActivate();
initializeErrorMonitoring();

initializeDebugger();

const errorHandler = (error: any) => {
  reportErrorToSentry(error);
};

const FpDashboardInit = ({ children }: FpAppsInitProps) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback} onError={errorHandler}>
      <ThemeProvider>
        <ConfigurationProvider>
          <AuthProviderWrapper>
            <UserInfoProvider>
              <FpApiProviderWrapper>{children}</FpApiProviderWrapper>
            </UserInfoProvider>
          </AuthProviderWrapper>
        </ConfigurationProvider>
      </ThemeProvider>
    </ErrorBoundary>
  );
};

export default FpDashboardInit;
