import { ReactNode } from "react";

type FilterDropdownContainerProps = {
  showFilter: boolean;
  children: ReactNode;
};

function FilterDropdownContainer({ showFilter, children }: Readonly<FilterDropdownContainerProps>) {
  return showFilter ? (
    <div
      className="shadow-4xl bg-neutral-0 dark:bg-neutral-dark-20 dark:border-neutral-dark-10 absolute right-0 bottom-0 top-10 z-10 h-full w-72 px-4 py-2 dark:shadow-transparent"
      style={{ top: "47px" }}
    >
      {children}
    </div>
  ) : null;
}
export default FilterDropdownContainer;
