import { Icon, Text } from "pixel";
import { ButtonHTMLAttributes } from "react";

const filterButtonDefaultStyles = `shadow-4xl items-center bg-neutral-0 flex w-72 justify-between px-4 py-2 text-left h-12 dark:bg-neutral-dark-5 dark:shadow-transparent rounded-md`;
const filterButtonCollapsed = `${filterButtonDefaultStyles} rounded-md`;
const filterButtonExpanded = `${filterButtonDefaultStyles} rounded-t-md`;

const getFilterClassname = (showFilter: boolean) => {
  return showFilter ? filterButtonExpanded : filterButtonCollapsed;
};

const getFilterIcon = (showFilter: boolean) => {
  return showFilter ? <Icon name="Close" /> : <Icon name="Filter" />;
};

interface FilterButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  showFilter: boolean;
}

function FilterButton({ showFilter, ...rest }: Readonly<FilterButtonProps>) {
  return (
    <button {...rest} className={getFilterClassname(showFilter)}>
      <Text>Filter</Text>
      <span>{getFilterIcon(showFilter)}</span>
    </button>
  );
}
export default FilterButton;
