import { Fp } from "@fintechprimitives/fpapi";
import { reportErrorToSentry } from "../../global/sentry/sentry";
import type FpApi from "../fpapi/fpapi";

export interface Config {
  baseURL: string;
  internalURL?: string;
  authEndpoint?: string;
  authClientId?: string;
  fpTenantClientId?: string;
  fpTenantClientSecret?: string;
  fpTenant: string;
  invokePartnerFlow?: boolean;
  semi_public?: {
    username: string;
    password: string;
  };
  employer?: {
    accessToken: string;
  };
  admin?: {
    accessToken: string;
  };
  internal_admin?: {
    accessToken: string;
  };
  fpInternalTenantClientId: string;
  fpInternalTenantClientSecret: string;
  fpInternalTenant: string;
}

class FpApiRoboxWrapper {
  // Donot use this constructor direcly. Use the static build methods below
  // Why? https://dev.to/somedood/the-proper-way-to-write-async-constructors-in-javascript-1o8c
  baseURL: string;
  internalURL: string;
  userAuthService: any;
  tenant_token?: string;
  tenantFpClient: any;
  semi_public_token?: string;
  fpClient: any;
  semiPublicFpClient: any;
  investor_token?: string;
  investorFpClient: any;
  employerToken?: string;
  adminToken?: string;
  internalAdminToken?: string;
  employerClient: Fp | null;
  internalClient: Fp | null;
  adminClient: Fp | null;
  internalAdminClient: Fp | null;

  private constructor(dashboardFpApi: FpApi) {
    this.baseURL = dashboardFpApi.fpDashboardApiBaseURL ?? "";
    this.internalURL = dashboardFpApi?.fpDashboardApiBaseURL ?? "";
    this.userAuthService = null;
    this.tenant_token = dashboardFpApi?.adminToken;
    this.tenantFpClient = dashboardFpApi?.fpDashboardClient ?? null;
    this.semi_public_token = dashboardFpApi?.adminToken;
    this.fpClient = dashboardFpApi?.fpDashboardClient ?? null;
    this.semiPublicFpClient = dashboardFpApi?.fpDashboardClient ?? null;
    this.investor_token = dashboardFpApi?.adminToken;
    this.investorFpClient = dashboardFpApi?.fpDashboardClient ?? null;
    this.employerToken = dashboardFpApi?.adminToken;
    this.adminToken = dashboardFpApi?.adminToken;
    this.internalAdminToken = dashboardFpApi?.adminToken;
    this.employerClient = dashboardFpApi?.fpDashboardClient ?? null;
    this.internalClient = dashboardFpApi?.fpDashboardClient ?? null;
    this.adminClient = dashboardFpApi?.fpDashboardClient ?? null;
    this.internalAdminClient = dashboardFpApi?.fpDashboardClient ?? null;
  }

  static async build(dashboardFpApi: FpApi) {
    try {
      const fpapi = new FpApiRoboxWrapper(dashboardFpApi);
      return fpapi;
    } catch (e) {}
  }

  async getInvestorProfile() {
    const _user = await this?.userAuthService?.getAuthenticatedUser();
    if (_user ?? _user?.profile?.fp_identifier) {
      return this?.fpClient?.investor_profiles()?.fetch(_user?.profile?.fp_identifier);
    }
    return null;
  }

  // USER OBJECT METHODS
  fetchUser(username: string, tenant?: string) {
    return this.fpClient
      .custom_users()
      .listAll({ username }, tenant ?? "")
      .then((res: any) => {
        return res?.data;
      })
      .catch((err: any) => {
        reportErrorToSentry(err);

        throw new Error("Failed to fetch user", err);
      });
  }

  updateUser(userid: any, annexure: any, profile = null, tenant?: string) {
    const currentTimestamp = Number(new Date());
    const userUpdateObject: any = {
      id: userid,
      annexure: {
        ...annexure,
        updated_at: `${currentTimestamp}`,
      },
    };

    if (profile) {
      userUpdateObject["profile"] = profile;
    }

    const client = this.fpClient ? this.fpClient : this.semiPublicFpClient;

    return client
      .custom_users()
      .update(userUpdateObject, tenant ?? "")
      .then(() => {
        return true;
      })
      .catch((err: any) => {
        reportErrorToSentry(err);

        throw new Error("Failed to update user", err);
      });
  }

  // PAYEMENT -> RAZOR PAY METHODS
  read_payment_callback() {
    const paymentParams: any = new URLSearchParams(window.location.search);
    if (paymentParams.get("status") === "success") {
      // Payment is successful
      sessionStorage.setItem("payment_status", "success");
      sessionStorage.setItem("payment_id", paymentParams.get("payment_id"));
    } else {
      // Payment is unsuccessful
      sessionStorage.setItem("payment_status", "failure");
      sessionStorage.setItem("payment_id", paymentParams.get("payment_id"));
    }
  }

  submitPayment(
    url: string,
    razorpay_payment_id: string,
    razorpay_order_id: string,
    razorpay_signature: string
  ) {
    const form_data = new FormData();
    form_data.append("razorpay_payment_id", razorpay_payment_id);
    form_data.append("razorpay_order_id", razorpay_order_id);
    form_data.append("razorpay_signature", razorpay_signature);

    const request = new Request(url, {
      method: "post",
      body: form_data,
    });

    return fetch(request).then(function (res) {
      if (!res.ok) throw new Error("Failed to submit the payment");
      return true;
    });
  }

  submitPaymentError(url: string, error: string, error_code: string, error_description: string) {
    const form_data = new FormData();
    form_data.append("error", error);
    form_data.append("error[code]", error_code);
    form_data.append("error[description]", error_description);

    const request = new Request(url, {
      method: "post",
      body: form_data,
    });

    return fetch(request).then(function (res) {
      if (!res.ok) throw new Error("Failed to submit the payment error");
      return true;
    });
  }

  async fetchEmailAndPhone(investorProfile: any) {
    try {
      const emailResponse = await this?.fpClient
        ?.email_addresses()
        ?.fetchAll({ profile: investorProfile?.id });
      const emailData = emailResponse?.data[0];
      const phoneResponse = await this?.fpClient
        ?.phone_numbers()
        ?.fetchAll({ profile: investorProfile?.id });
      const phoneData = phoneResponse?.data[0];
      return { emailData, phoneData };
    } catch (e) {
      reportErrorToSentry(e);

      throw new Error("Failed to fetch contact details");
    }
  }
}

export default FpApiRoboxWrapper;
